const prodConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

const devConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

const userRole = {
  ROLE_FRANCHISOR: "ROLE_FRANCHISOR",
  ROLE_FRANCHISEE: "ROLE_FRANCHISEE",
  ROLE_ADMIN: "ROLE_ADMIN"
};

const AUTH0_CONNECTION_USER_PASSWORD = "Username-Password-Authentication";
const AUTH0_CONNECTION_EMAIL = "email";

export {
  prodConfig,
  devConfig,
  userRole,
  AUTH0_CONNECTION_EMAIL,
  AUTH0_CONNECTION_USER_PASSWORD
};
