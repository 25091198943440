import { BlockOffice, BlockPayroll } from "@components/underwriting";
import { Loading } from "@components";
import React, { useEffect, useState, useMemo } from "react";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";

export default function PanelPayroll({
  franchiseeData,
  franchisorData,
  setFranchiseeData,
  onNext,
  onPrevious,
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [formVal, setFormVal] = useState([]);
  const [officeForm, setOfficeForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const locationList = useMemo(() => {
    return (franchiseeData?.commonLocList || []).map((item, index) => ({
      value: index,
      label: `${item.address}, ${item.city}, ${item.state}, ${item.zip}`,
    }));
  }, [franchiseeData]);

  const codeList = useMemo(() => {
    if (franchisorData?.naics_classes?.length > 0) {
      return franchisorData?.naics_classes
        .filter((item) => item.eleType === "WC")
        .map((item) => ({
          code: item.code,
          description: item.description,
        }));
    }

    return null;
  }, [franchisorData]);

  useEffect(() => {
    setFormVal(handleGetFormVal());
    setOfficeForm(handleGetOfficeForm());
  }, [franchiseeData]);

  function handleGetFormVal() {
    const payrollList = franchiseeData?.payrollList || [];

    return [...payrollList];
  }

  function handleGetOfficeForm() {
    const officeList = franchiseeData?.officeList || [];
    return [...officeList];
  }

  function validateForm() {
    const hasPayrollErrors = formVal.some((payroll) =>
      payroll.payrollItem.some(
        (item) =>
          !item.annual_payroll ||
          !item.full_time_emp ||
          !item.part_time_emp
      )
    )

    const hasOfficeErrors = officeForm.some((office) =>
      !office.full_name || !office.payroll || !office.owner_ship
    )

    return hasPayrollErrors || hasOfficeErrors
  }

  async function handleSave() {
    setIsSubmit(true)

    if (validateForm()) {
      notification.error({
        message: "Validation Error",
        description: "Please correct the highlighted errors before saving.",
        duration: 5
      })
      return
    }

    setIsSubmit(false);
    setLoading(true);
    try {
      const logPayrollList = franchiseeData?.logPayrollList || [];

      logPayrollList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal,
      });
      const logOfficeList = franchiseeData?.logOfficeList || [];

      logOfficeList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: officeForm,
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          payrollList: formVal,
          logPayrollList,
          officeList: officeForm,
          logOfficeList,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFranchiseeData(result?.data?.entity);
      notification.success({
        message: "Success",
        description: "Payroll Information is successfully updated!",
        duration: 5,
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Payroll Information failed! Please contact admin directly.",
        duration: 5,
      });
    }
    setLoading(false);
  }

  const isFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetFormVal());
    const changedStr = JSON.stringify(formVal);

    return originalStr != changedStr;
  }, [formVal, franchiseeData]);

  const isOfficeFormDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetOfficeForm());
    const changedStr = JSON.stringify(officeForm);

    return originalStr != changedStr;
  }, [officeForm, franchiseeData]);

  return (
    <div className="pan_driver">
      <div className="pan_driver-info">
        <h5 className="pan_driver-info-title">Payroll</h5>
        <BlockPayroll
          data={formVal}
          codeList={codeList}
          locationList={locationList}
          onChange={setFormVal}
          isSubmit={isSubmit}
        />
        <br />

        <h5 className="pan_driver-info-title">Owners & Officers</h5>
        <BlockOffice
          codeList={codeList}
          data={officeForm}
          onChange={setOfficeForm}
          isSubmit={isSubmit}
        />

        <div className="pan_vehicle-info-footer">
          {onPrevious && (
            <Button
              onClick={() => {
                onPrevious();
              }}
              size="large"
              type=""
            >
              {`«`} Previous
            </Button>
          )}
          <Button
            onClick={() => {
              handleSave();
            }}
            size="large"
            type="primary"
          >
            Save & Next
          </Button>
          <Button
            onClick={() => {
              onNext();
            }}
            size="large"
            type=""
          >
            Skip {`»`}
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
