import React, { useMemo, useState } from "react";
import Card from "@components/card";
import { Card as AntCard, Switch, Radio, Button } from "antd";
import "./style.scss";
import Input from "@components/input";
import { useAuth0 } from "@auth0/auth0-react";
import { post, put } from "@utils/axios";
import { getPrimaryContact } from "@utils/common";
import Loading from "@components/loading";
import {
  DOC_KEY_APPLICATION,
  DOC_KEY_DRIVER_SCHEDULE,
  DOC_KEY_EXISTING_POLICIES,
  DOC_KEY_FINANCIAL,
  DOC_KEY_LOSS_RUNS,
  DOC_KEY_OTHER,
  DOC_KEY_QUOTES,
  DOC_KEY_VEHICLE_SCHEDULE,
  DOC_LABEL_APPLICATION,
  DOC_LABEL_DRIVER_SCHEDULE,
  DOC_LABEL_EXISTING_POLICIES,
  DOC_LABEL_FINANCIAL,
  DOC_LABEL_LOSS_RUNS,
  DOC_LABEL_OTHER,
  DOC_LABEL_QUOTES,
  DOC_LABEL_VEHICLE_SCHEDULE,
  SECTION_KEY_BUILD_OUT,
  SECTION_KEY_DRIVERS,
  SECTION_KEY_ENTITY,
  SECTION_KEY_EQUIPMENT,
  SECTION_KEY_PAYROLL,
  SECTION_KEY_POC,
  SECTION_KEY_PROPERTY,
  SECTION_KEY_SUBCONTRACTOR,
  SECTION_KEY_UNIQUE,
  SECTION_KEY_VEHICLES,
  SECTION_LABEL_BUILD_OUT,
  SECTION_LABEL_DRIVERS,
  SECTION_LABEL_ENTITY,
  SECTION_LABEL_EQUIPMENT,
  SECTION_LABEL_PAYROLL,
  SECTION_LABEL_POC,
  SECTION_LABEL_PROPERTY,
  SECTION_LABEL_SUBCONTRACTOR,
  SECTION_LABEL_UNIQUE,
  SECTION_LABEL_VEHICLES,
  TEMPLATE_KEY_ENDORSEMENT_REQUEST,
  TEMPLATE_KEY_END_OF_YEAR_RENEWAL,
  TEMPLATE_KEY_MID_TERM_AUDIT,
  TEMPLATE_KEY_MISSING_DATA_FOLLOW_UP,
  TEMPLATE_KEY_NEW_FRANCHISEE,
  TEMPLATE_LABEL_ENDORSEMENT_REQUEST,
  TEMPLATE_LABEL_END_OF_YEAR_RENEWAL,
  TEMPLATE_LABEL_MID_TERM_AUDIT,
  TEMPLATE_LABEL_MISSING_DATA_FOLLOW_UP,
  TEMPLATE_LABEL_NEW_FRANCHISEE
} from "@assets/const/ui";

const VISIBLE_SECTION = [
  {
    value: SECTION_KEY_ENTITY,
    label: SECTION_LABEL_ENTITY
  },
  {
    value: SECTION_KEY_POC,
    label: SECTION_LABEL_POC
  },
  {
    value: SECTION_KEY_UNIQUE,
    label: SECTION_LABEL_UNIQUE
  },
  {
    value: SECTION_KEY_DRIVERS,
    label: SECTION_LABEL_DRIVERS
  },
  {
    value: SECTION_KEY_VEHICLES,
    label: SECTION_LABEL_VEHICLES
  },
  {
    value: SECTION_KEY_BUILD_OUT,
    label: SECTION_LABEL_BUILD_OUT
  },
  {
    value: SECTION_KEY_PAYROLL,
    label: SECTION_LABEL_PAYROLL
  },
  {
    value: SECTION_KEY_SUBCONTRACTOR,
    label: SECTION_LABEL_SUBCONTRACTOR
  },
  {
    value: SECTION_KEY_PROPERTY,
    label: SECTION_LABEL_PROPERTY
  },
  {
    value: SECTION_KEY_EQUIPMENT,
    label: SECTION_LABEL_EQUIPMENT
  }
];

const DOC_SECTION = [
  {
    value: DOC_KEY_LOSS_RUNS,
    label: DOC_LABEL_LOSS_RUNS
  },
  {
    value: DOC_KEY_EXISTING_POLICIES,
    label: DOC_LABEL_EXISTING_POLICIES
  },
  {
    value: DOC_KEY_QUOTES,
    label: DOC_LABEL_QUOTES
  },
  {
    value: DOC_KEY_APPLICATION,
    label: DOC_LABEL_APPLICATION
  },
  {
    value: DOC_KEY_DRIVER_SCHEDULE,
    label: DOC_LABEL_DRIVER_SCHEDULE
  },
  {
    value: DOC_KEY_VEHICLE_SCHEDULE,
    label: DOC_LABEL_VEHICLE_SCHEDULE
  },
  {
    value: DOC_KEY_FINANCIAL,
    label: DOC_LABEL_FINANCIAL
  },
  {
    value: DOC_KEY_OTHER,
    label: DOC_LABEL_OTHER
  }
];

const EMAIL_TEMPLATE = [
  {
    value: TEMPLATE_KEY_NEW_FRANCHISEE,
    label: TEMPLATE_LABEL_NEW_FRANCHISEE
  },
  {
    value: TEMPLATE_KEY_MID_TERM_AUDIT,
    label: TEMPLATE_LABEL_MID_TERM_AUDIT
  },
  {
    value: TEMPLATE_KEY_END_OF_YEAR_RENEWAL,
    label: TEMPLATE_LABEL_END_OF_YEAR_RENEWAL
  },
  {
    value: TEMPLATE_KEY_MISSING_DATA_FOLLOW_UP,
    label: TEMPLATE_LABEL_MISSING_DATA_FOLLOW_UP
  },
  {
    value: TEMPLATE_KEY_ENDORSEMENT_REQUEST,
    label: TEMPLATE_LABEL_ENDORSEMENT_REQUEST
  }
];

export default function RequestClientForm({
  franchiseeData,
  setFranchiseeData
}) {
  const [selectedPOCIndex, setSelectedPOCIndex] = useState(-1);
  const [visibleSec, setVisibleSec] = useState({});
  const [visibleDoc, setVisibleDoc] = useState({});
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();

  async function handleSaveRequest(requestItem) {
    setLoading(true);
    try {
      const logRequest = franchiseeData?.logRequest || [];
      logRequest.push({
        ...requestItem,
        requestTo: requestItem.requestTo.email
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          logRequest
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const dba =
        franchiseeData?.insuranceData?.dba || franchiseeData?.dba || "";

      const addUserUrl = `${window.location.origin}/board/add-user`;

      const resultRequest = await post(
        `reports/email-request`,
        {
          template: requestItem.template,
          firstName: requestItem.requestTo.firstName,
          url: requestItem.url,
          addUserURL: addUserUrl,
          email: requestItem.requestTo.email,
          dba
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
    } catch (error) {}
    setLoading(false);
    handleCancelRequest();
  }

  function handleCancelRequest() {
    setSelectedPOCIndex(-1);
    setTemplate(null);
    setVisibleSec({});
    setVisibleDoc({});
  }

  function handleSecChange(key, value) {
    setVisibleSec({
      ...visibleSec,
      [key]: value
    });
  }

  function handleDocChange(key, value) {
    setVisibleDoc({
      ...visibleDoc,
      [key]: value
    });
  }

  async function handleRequest() {
    const secVisible = Object.entries(visibleSec)
      .filter((item) => item[1])
      .map((item) => item[0]);
    const docVisible = Object.entries(visibleDoc)
      .filter((item) => item[1])
      .map((item) => item[0]);

    const paramObj = {
      sec: secVisible,
      doc: docVisible,
      template: template
    };

    const parsedData = new URLSearchParams(paramObj);
    const scheduleLink = `${
      window.location.origin
    }/board/schedule?${parsedData.toString()}`;

    await handleSaveRequest({
      requestTo: pocContactList[selectedPOCIndex],
      requestBy: user.email,
      requestAt: new Date(),
      sec: secVisible,
      doc: docVisible,
      url: scheduleLink,
      template: template
    });
  }

  const pocContactList = useMemo(() => {
    const primaryContact = getPrimaryContact(franchiseeData);
    const pocList = JSON.parse(
      JSON.stringify(franchiseeData?.poc_contacts || [])
    );
    if (
      !pocList.find((contactItem) => contactItem?.email === primaryContact?.email)
    ) {
      pocList.unshift(primaryContact);
    }
    return pocList;
  }, [franchiseeData]);

  return (
    <Card className="re_cl" title="Request Client Information">
      <AntCard
        className="re_cl-wrap"
        title="Which scheduling information do you want the insured to provide?"
      >
        <div className="re_cl-con">
          {VISIBLE_SECTION.map((visibleItem) => (
            <div className="re_cl-con-item">
              <Switch
                checked={visibleSec[visibleItem?.value]}
                onChange={(checked) =>
                  handleSecChange(visibleItem?.value, checked)
                }
              ></Switch>
              <div className="re_cl-con-item-label">{visibleItem?.label}</div>
            </div>
          ))}
        </div>
      </AntCard>

      <AntCard
        className="re_cl-wrap"
        title="Which documents will you be requesting?"
      >
        <div className="re_cl-con">
          {DOC_SECTION.map((docItem) => (
            <div className="re_cl-con-item">
              <Switch
                checked={visibleDoc[docItem.value]}
                onChange={(checked) => handleDocChange(docItem.value, checked)}
              ></Switch>
              <div className="re_cl-con-item-label">{docItem?.label}</div>
            </div>
          ))}
        </div>
      </AntCard>

      <AntCard
        className="re_cl-wrap"
        title="Which contact do you want to make the information request to?"
      >
        <div className="re_cl-poc">
          {pocContactList.map((item, index) => (
            <div
              className={`re_cl-poc-card ${
                selectedPOCIndex === index ? "active-card" : ""
              }`}
              onClick={() => setSelectedPOCIndex(index)}
            >
              <div className="re_cl-poc-card-ele">
                <label>First Name:</label>
                <p>{item?.firstName}</p>
              </div>
              <div className="re_cl-poc-card-ele">
                <label>Last Name:</label>
                <p>{item?.lastName}</p>
              </div>
              <div className="re_cl-poc-card-ele">
                <label>Email:</label>
                <p>{item?.email}</p>
              </div>
              <div className="re_cl-poc-card-ele">
                <label>Telephone:</label>
                <p>{item?.telephone}</p>
              </div>
            </div>
          ))}
        </div>
      </AntCard>

      <AntCard
        className="re_cl-wrap"
        title="Which email template do you need to send?"
      >
        <div className="re_cl-con">
          <Radio.Group
            value={template}
            onChange={(evt) => setTemplate(evt.target.value)}
          >
            {EMAIL_TEMPLATE.map((item) => (
              <Radio value={item?.value}>{item?.label}</Radio>
            ))}
          </Radio.Group>
        </div>
      </AntCard>
      <div className="re_cl-footer">
        <Button onClick={handleCancelRequest}>Cancel</Button>
        <Button
          type="primary"
          disabled={selectedPOCIndex === -1}
          onClick={handleRequest}
        >
          Send Request
        </Button>
      </div>
      <Loading loading={loading} />
    </Card>
  );
}
