import React, { useEffect, useState, useRef } from "react";
import UnderWritingQuestion from "../../../admin-board/franchisee-overview/underwriting-question";
import { useAppState } from "@context/index";
import { buildCustomQuestions } from "../../../admin-board/franchisee-detail/under-writing";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";

export default function PanelQuestion({
  franchiseeData,
  onNext,
  onPrevious,
  setFranchiseeData,
}) {
  const [customQuestions, setCustomQuestions] = useState([]);
  const { authSetting, franchisorAdmin } = useAppState();

  const { getAccessTokenSilently, user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const customQuestionsRef = useRef([]);

  useEffect(() => {
    if (franchiseeData && franchisorAdmin?.custom_questions) {
      const initialQuestions = buildCustomQuestions(
        franchisorAdmin.custom_questions,
        franchiseeData.customQuestions || []
      );
      setCustomQuestions(initialQuestions);
      customQuestionsRef.current = initialQuestions;
    }
  }, [franchiseeData, franchisorAdmin]);

  useEffect(() => {
    customQuestionsRef.current = customQuestions;
  }, [customQuestions]);

  function validateQuestions() {
    return customQuestions.some((section) =>
      section.questions.some((question) => {
        const value = question.value;

        if (question.eleType === "number") {
          return value === "" || value === null || isNaN(value);
        }
        if (question.eleType === "boolean") {
          return value === null || value === undefined;
        }
        if (question.eleType === "string") {
          return value === "" || value === null;
        }
        if (question.eleType === "select") {
          return value === "" || value === null;
        }
        if (question.eleType === "paragraph") {
          return value === "" || value === null;
        }
        return false;
      })
    );
  }

  async function handleSave() {
    setIsSubmit(true);

    if (validateQuestions()) {
      notification.error({
        message: "Validation Error",
        description: "Please fill out all required fields before saving.",
        duration: 5
      })
      return
    }

    setIsSubmit(false);
    setLoading(true);
    try {
      const customQuestionsRes =
        franchisorAdmin?.custom_questions?.map((ele) => ({
          section: ele?.section || "",
          questions:
            ele?.questions?.map((question) => ({
              title: question?.title || "",
              value: question?.value || [],
              eleType: question?.eleType || "",
            })) || [],
        })) || [];

      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        { customQuestions: customQuestionsRef.current },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (result?.data?.entity?.customQuestions) {
        setFranchiseeData(result.data.entity);
        notification.success({
          message: "Success",
          description: "Custom Question has successfully updated!",
          duration: 5,
        });
      } else {
        throw new Error("CustomQuestions not updated in response");
      }
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Custom Questions failed! Please contact admin directly.",
        duration: 5,
      });
    }
    setLoading(false);
  }

  return (
    <div>
      <UnderWritingQuestion
        onChange={(updatedQuestions) => {
          setCustomQuestions(updatedQuestions);
        }}
        isCustom={true}
        customQuestions={customQuestions}
        hideSaveButton={true}
        allowChangeQuestions={false}
        isSubmit={isSubmit}
      />
      <div className="question-footer">
        {onPrevious && (
          <Button
            onClick={() => {
              onPrevious();
            }}
            size="large"
            type=""
          >
            {`«`} Previous
          </Button>
        )}
        <Button
          onClick={() => {
            handleSave();
          }}
          size="large"
          type="primary"
        >
          Save & Next
        </Button>
        <Button
          onClick={() => {
            onNext();
          }}
          size="large"
          type=""
        >
          Skip {`»`}
        </Button>
      </div>
    </div>
  );
}
