import { BlockBuildOut } from "@components/underwriting";
import { Loading } from "@components";
import React, { useEffect, useState, useMemo } from "react";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";
import { NotificationTwoTone } from "@ant-design/icons";

export default function PanelBuildOut({
  franchiseeData,
  setFranchiseeData,
  onNext,
  onPrevious,
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [formVal, setFormVal] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false)

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [franchiseeData]);

  function handleGetFormVal() {
    return franchiseeData?.buildOutInfo || {};
  }

  function validateForm() {
    const requiredFields = [
      "address",
      "city",
      "state",
      "zip",
      "yearBuilt",
      "squareFeet",
      "numOfStories",
      "buildOutCost",
    ];

    return requiredFields.some((field) => !formVal[field]);
  }


  async function handleSave() {
    setIsSubmit(true);

    if (validateForm()) {
      notification.error({
        message: "Validation Error",
        description: "Please fill out all required fields before saving.",
        duration: 5
      })
      return
    }

    setIsSubmit(false);

    setLoading(true);
    try {
      const logBuildOutInfo = franchiseeData?.logBuildOutInfo || [];

      logBuildOutInfo.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal,
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          buildOutInfo: formVal,
          logBuildOutInfo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFranchiseeData(result?.data?.entity);
      notification.success({
        message: "Success",
        description: "Build Out Information is successfully updated!",
        duration: 5,
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Build Out Information failed! Please contact admin directly.",
        duration: 5,
      });
    }

    setLoading(false);
  }

  const isFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetFormVal());
    const changedStr = JSON.stringify(formVal);

    return originalStr != changedStr;
  }, [formVal, franchiseeData]);

  return (
    <div className="pan_build">
      <div className="pan_build-info">
        <BlockBuildOut data={formVal} onChange={setFormVal} isSubmit={isSubmit}/>
        <div className="pan_build-info-footer">
          {onPrevious && (
            <Button
              onClick={() => {
                onPrevious();
              }}
              size="large"
              type=""
            >
              {`«`} Previous
            </Button>
          )}
          <Button
            onClick={() => {
              handleSave();
            }}
            size="large"
            type="primary"
          >
            Save & Next
          </Button>
          <Button
            onClick={() => {
              onNext();
            }}
            size="large"
            type=""
          >
            Skip {`»`}
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
