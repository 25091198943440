import React from "react";
import "./style.scss";
import { useAppState } from "@context/index";
import { Header, Sidebar } from "../../components";
import FranchiseeOverview from "./franchisee-overview";
import FranchiseeDocuments from "./franchisee-documents";
import FranchiseeSettings from "./franchisee-settings";
import FranchiseeSchedule from "./franchisee-schedule";
import FranchiseeAddUser from "./franchisee-email-add-user"
import { Switch, Route } from "react-router-dom";
import { VIEW_SOURCE_ZEE } from "@assets/const/ui";
import FranchiseeReport from "../admin-board/franchisee-report";
import { Layout } from "antd";

const { Content } = Layout;

export default function FranchiseeBoard() {
  const { authSetting, requirement } = useAppState();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar screen="board" />
      <Layout>
        <Header screen="dashboard" />
        <Content>
          <Switch>
            <Route
              path="/board/report"
              render={(props) => (
                <FranchiseeReport
                  {...props}
                  viewSource={VIEW_SOURCE_ZEE}
                  zeeId={authSetting?.user?.contactId}
                />
              )}
            />
            <Route path="/board/documents" component={FranchiseeDocuments} />
            <Route path="/board/settings" component={FranchiseeSettings} />
            <Route path="/board/schedule" component={FranchiseeSchedule} />
            <Route path="/board/account" component={FranchiseeOverview} />
            <Route path="/board/add-user" component={FranchiseeAddUser}/>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
