import React from "react";
import _ from "lodash";
import { Input, Card } from "@components";
import { Button, Select } from "antd";
import { DeleteOutlined, HomeOutlined, CodeOutlined } from "@ant-design/icons";
import "./style.scss";
import CodeInput from "@components/code-input";

export default function BlockContractor({
  data,
  locationList,
  codeList,
  onChange,
  disabled,
  isSubmit
}) {
  function handleDeleteLocation(index) {
    if (data.length === 0) return;

    onChange((prev) => {
      prev.splice(index, 1);

      return [...prev];
    });
  }

  function handleChangeSubcontractorItem(index, itemIndex, key, value) {
    onChange((prev) => {
      prev[index].subcontractorItem[itemIndex][key] = value;

      return [...prev];
    });
  }
  function handleChangeSubcontractorItemCode(index, itemIndex, value) {
    const codeItem = codeList.find((item) => item.code === value);

    onChange((prev) => {
      prev[index].subcontractorItem[itemIndex]["class_code"] = value;
      prev[index].subcontractorItem[itemIndex]["desc"] = codeItem?.description;

      return [...prev];
    });
  }

  function handleChangeSubcontractorInfo(index, key, value) {
    onChange((prev) => {
      prev[index][key] = value;

      return [...prev];
    });
  }

  function firstTimeNewLocation() {
    onChange((prev) => [
      {
        locationIndex: null,
        subcontractorItem: [],
      }
    ])
  }

  // function handleAddNewLocation(index) {
  //   onChange((prev) => {
  //     prev.push({
  //       locationIndex: null,
  //       subcontractorItem: []
  //     });

  //     return [...prev];
  //   });
  // }

  function handleAddNewLocation() {
    onChange((prev) => [
      ...prev,
      {
        locationIndex: null,
        subcontractorItem: [],
      },
    ]);
  }

  function handleAddNewSubcontractor(index) {
    onChange((prev) => {
      prev[index].subcontractorItem.push({
        class_code: "",
        desc: "",
        expense: ""
      });

      return [...prev];
    });
  }

  function handleDeleteSubcontractor(index, itemIndex) {
    if (data.length === 0) return;

    onChange((prev) => {
      prev[index].subcontractorItem.splice(itemIndex, 1);

      return [...prev];
    });
  }

  function renderSubcontractorItem(subcontractorItem, index, itemIndex) {
    return (
      <div className="block_co-item-sub">
        <div className="form-group-three-container">
          <CodeInput
            options={codeList}
            value={subcontractorItem.class_code}
            onChange={(evt) =>
              handleChangeSubcontractorItemCode(index, itemIndex, evt)
            }
          />
          {/* <Input
            title="Class Code"
            type="text"
            value={subcontractorItem.class_code}
            required
            onChange={(evt) =>
              handleChangeSubcontractorItem(
                index,
                itemIndex,
                "class_code",
                evt.target.value
              )
            }
          />
          <Input
            title="Description"
            type="text"
            value={subcontractorItem.desc}
            required
            onChange={(evt) =>
              handleChangeSubcontractorItem(
                index,
                itemIndex,
                "desc",
                evt.target.value
              )
            }
          /> */}
          <Input
            title="Uninsured Subcontractor Expenses"
            type="text"
            value={subcontractorItem.expense}
            required
            onChange={(evt) =>
              handleChangeSubcontractorItem(
                index,
                itemIndex,
                "expense",
                evt.target.value
              )
            }
            error={
              isSubmit && (!subcontractorItem.expense || subcontractorItem.expense === "")
            }
            errorMsg="Uinsured Subcontractor Expenses are required"
          />
        </div>
        <div className="block_co-item-sub-action">
          {!disabled && (
            <Button
              className="block_co-item-sub-action-btn"
              onClick={() => handleDeleteSubcontractor(index, itemIndex)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  function renderSubcontractorInfo(index, subcontractor) {
    return (
      <div key={`contract-${index}`} className="block_co-item">
        <div className="block_co-item-con">
          <div className="block_co-item-loc">
            <p className="input-title">
              Select A Physical Location (No Billing/Mailing Address)
            </p>
            <div className="block_co-item-loc-select">
              <Select
                size="large"
                style={{ minWidth: "300px" }}
                options={locationList}
                value={subcontractor.locationIndex}
                onSelect={(evt) =>
                  handleChangeSubcontractorInfo(index, "locationIndex", evt)
                }
              ></Select>
            </div>
          </div>
          {subcontractor.subcontractorItem.map((contractItem, contractIndex) =>
            renderSubcontractorItem(contractItem, index, contractIndex)
          )}
          <div className="block_co-item-footer">
            {!disabled && (
              <div className="admin_franchisee_under-section-footer">
                <Button
                  className="admin_franchisee_under-section-footer-link"
                  onClick={() => handleAddNewSubcontractor(index)}
                >
                  <CodeOutlined />
                  Add Code
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="block_co-item-action">
          {!disabled && (
            <Button
              className="block_co-item-action-btn"
              onClick={() => handleDeleteLocation(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="block_co">
      {data.length === 0 && firstTimeNewLocation()}
      {data.map((subcontractor, index) =>
        renderSubcontractorInfo(index, subcontractor)
      )}
      {!disabled && (
        <div className="block_co-footer">
          <Button
            className="block_co-footer-link"
            onClick={handleAddNewLocation}
          >
            <HomeOutlined />
            Add Address
          </Button>
        </div>
      )}
    </div>
  );
}
