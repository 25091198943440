import React, { useState, useEffect, useMemo } from "react";
import { Input, Button, notification, Switch, Select } from "antd";
import { Loading } from "../../../../components";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppState } from "../../../../context";
import { post, put } from "../../../../utils/axios";
import { PERMISSION_TABLE, ACCESS_FULL } from "../../../../utils/permission";
import { DeleteOutlined, PlusOutlined, CopyOutlined } from "@ant-design/icons";
import "./style.scss";
import { notify } from "@utils/common";
import _ from "lodash";
const { TextArea } = Input;
const { Option } = Select;

const UW_QUESTION = [
  {
    section: "General Liability / Garagekeepers / Property",
    questions: [
      {
        title:
          "Has the applicant operated without insurance coverage for 6 months or more since the business started?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title:
          "Has the applicant had any losses associated with the lines selected within the past three years?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },

      {
        title:
          "How many cars at any one location are being washed at the same time?",
        value: "",
        correctValue: "",
        eleType: "string"
      },
      {
        title: "Is the lot secured with a fence or security gate?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Any building 50% vacant?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Does the application sell any vehicles?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Is any towing done by the applicant?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Is this business operated from a private residence?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title:
          "Does the insured complete mechanical modifications or work on vehicles used in racing?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title:
          "Does the insured work on vehicles over 20,000 Gross Vehicle Weight, truck-tractors or on farm machinery?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title:
          "Is any work done on recreational vehicles, motorcycles or watercraft?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      }
    ]
  },
  {
    section: "Commercial Auto",
    questions: []
  },
  {
    section: "Workers Compensation",
    questions: [
      {
        title:
          "Does the insured conduct repossession, automobile dismantling or crushing, roadside assistance or repair, mobile repair, split rims, tire recapping, and/or retreading operations?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title:
          "Do any of the insured's employees participate in racing teams and/or events?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title:
          "Do employees of the insured work on RVs, commercial trucks, buses, ATVs, and/or motorcycles? ",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: `Does the insured do any work or exposure involving:
        Asbestos, lead, or mold
        Blasting, explosives, demolition, or wrecking
        Federal Coverages (USLH, FELA, Jones Act, Defense Base Act, etc.)
        Nuclear energy or research
        Manufacture, store, work with, or transport hazardous materials
        Own, operate, sell, or lease aircraft and/or watercraft that operate on navigable waterways
        Firearm manufacturing, testing, sales & service
        Perform any leasing, staffing, or provide temporary workers
        Oil, gas, mining, or on-site support work for those industries
        Dock or sea walls
        Marijuana or other federally illegal substances
        `,
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Does the insured have any operations after 2AM?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Does the applicant own, operate or lease aircraft/watercraft?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Are any of your employees leased to or donated to others?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Do employees travel or work outside the US on business?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: "Does the account present any driving exposures?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title:
          "Are more than 25% of your employees seasonal, voluntary, or temporary?",
        value: false,
        correctValue: false,
        eleType: "boolean"
      },
      {
        title: `Does the insured employ 1099’s?
        If Yes:
        Total % of 1099s
        `,
        value: false,
        correctValue: false,
        eleType: "boolean"
      }
    ]
  }
];

export default function UnderWritingQuestion({
  isCustom = false,
  allowChangeQuestions = true,
  customQuestions,
  hideSaveButton = false,
  onChange,
  isSubmit
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin, setFranchisorAdmin, authSetting } = useAppState();
  const [loading, setLoading] = useState(false);
  const [formVal, setFormVal] = useState(
    (isCustom
      ? customQuestions
      : franchisorAdmin?.uw_question || UW_QUESTION) || []
  );
  const [currSelectOptionInput, setCurrSelectOptionInput] = useState("");
  const editable = useMemo(
    () =>
      PERMISSION_TABLE["ZEE_RATING"][authSetting.userPermission] ===
      ACCESS_FULL,
    [authSetting]
  );

  useEffect(() => {
    setFormVal(
      (isCustom
        ? customQuestions
        : franchisorAdmin?.uw_question || UW_QUESTION) || []
    );
  }, [isCustom, customQuestions, franchisorAdmin]);

  function handleFormChange(newFormVal) {
    setFormVal(newFormVal);
    if (onChange) {
      onChange(newFormVal);
    }
  }

  function handleChangeSection(index, key, value) {
    formVal[index][key] = value;
    handleFormChange([...formVal]);
  }

  function handleChangeField(sIndex, qIndex, key, value) {
    formVal[sIndex].questions[qIndex][key] = value;
    handleFormChange([...formVal]);
  }

  function handleAddOptionToSelectQuestion(sIndex, qIndex, optionLabel) {
    if (!optionLabel) {
      notify("Error", "The select option cannot be empty");
      return;
    }
    const oIndex = (formVal[sIndex].questions[qIndex].options || []).length;
    const optionToAdd = {
      label: optionLabel,
      value: oIndex,
      key: oIndex
    };
    formVal[sIndex].questions[qIndex].options = formVal[sIndex].questions[
      qIndex
    ].options
      ? [...formVal[sIndex].questions[qIndex].options, optionToAdd]
      : [optionToAdd];
    handleFormChange([...formVal]);
  }

  function handleCopyLink(link) {
    navigator.clipboard.writeText(link);
    notification.open({
      message: "Public Portal Link was copied",
      description: "",
      duration: 3
    });
  }

  function handleDeleteOptionFromSelectQuestion(sIndex, qIndex, oIndex) {
    if (formVal[sIndex].questions[qIndex].options.length == 0) {
      return;
    }
    const copy = _.cloneDeep(formVal);
    if (
      copy[sIndex].questions[qIndex].options[oIndex].value ===
      copy[sIndex].questions[qIndex].value
    ) {
      delete copy[sIndex].questions[qIndex].value;
    }
    copy[sIndex].questions[qIndex].options.splice(oIndex, 1);
    handleFormChange([...copy]);
  }

  function handleChangeOptionForSelectQuestion(sIndex, qIndex, oIndex, label) {
    formVal[sIndex].questions[qIndex].options[oIndex].label = label;
    formVal[sIndex].questions[qIndex].options[oIndex].value = oIndex;
    handleFormChange([...formVal]);
  }

  function handleAdd(index) {
    formVal[index].questions.push({
      title: "",
      value: "",
      eleType: "boolean"
    });
    handleFormChange([...formVal]);
  }

  function handleDeleteQuestion(sIndex, qIndex) {
    formVal[sIndex].questions.splice(qIndex, 1);
    handleFormChange([...formVal]);
  }

  function handleAddSection() {
    formVal.push({
      section: "",
      questions: [
        {
          title: "",
          value: "",
          eleType: "boolean"
        }
      ]
    });
    handleFormChange([...formVal]);
  }

  function handleDeleteSection(index) {
    formVal.splice(index, 1);
    handleFormChange([...formVal]);
  }

  async function handleSave() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (franchisorAdmin?._id) {
        const result = await put(
          `requirement/${franchisorAdmin._id}`,
          isCustom ? { custom_questions: formVal } : { uw_question: formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      } else {
        const result = await post(
          `requirement`,
          isCustom ? { custom_questions: formVal } : { uw_question: formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      }
      setLoading(false);
    } catch (e) {
      handleNotification(
        "Failed",
        "Save form is failed. Please contact to admin directly."
      );
      setLoading(false);
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  return (
    <div className="underwriting_question">
      {formVal.map((sectionItem, sIndex) => (
        <div className="underwriting_question-section">
          {(!editable || !allowChangeQuestions) && (
            <div className="underwriting_question-section-title">
              {sectionItem.section}
            </div>
          )}
          {editable && allowChangeQuestions && (
            <Input
              size="large"
              value={sectionItem.section}
              onChange={(evt) =>
                handleChangeSection(sIndex, "section", evt.target.value)
              }
            />
          )}
          <div className="underwriting_question-section-main">
            {sectionItem.questions.map((questionItem, qIndex) => (
              <div className="underwriting_question-section-main-item">
                {(!editable || !allowChangeQuestions) && (
                  <div className="underwriting_question-section-main-item-ques">
                    {questionItem.title}
                  </div>
                )}
                {editable && allowChangeQuestions && (
                  <div>
                    <Input
                      size="large"
                      placeholder={
                        questionItem.eleType === "paragraph"
                          ? "Add underwriter inquiries here..."
                          : ""
                      }
                      value={questionItem.title}
                      onChange={(evt) =>
                        handleChangeField(
                          sIndex,
                          qIndex,
                          "title",
                          evt.target.value
                        )
                      }
                    />

                    {questionItem.eleType === "select" && (
                      <div className="underwriting_question-section-main-item-select">
                        {questionItem.options?.map((_, oIndex) => (
                          <div className="underwriting_question-section-main-item-select-option">
                            <div>
                              <Input
                                disabled
                                value={questionItem.options[oIndex].label}
                                onChange={(evt) =>
                                  handleChangeOptionForSelectQuestion(
                                    sIndex,
                                    qIndex,
                                    oIndex,
                                    evt.target.value
                                  )
                                }
                              />
                            </div>
                            <Button
                              danger
                              icon={<DeleteOutlined />}
                              className="underwriting_question-section-main-item-select-option-add-btn"
                              onClick={() => {
                                handleDeleteOptionFromSelectQuestion(
                                  sIndex,
                                  qIndex,
                                  oIndex
                                );
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        ))}
                        <div className="underwriting_question-section-main-item-select-option">
                          <div>
                            <Input
                              value={currSelectOptionInput}
                              onChange={(evt) =>
                                setCurrSelectOptionInput(evt.target.value)
                              }
                            />
                          </div>
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            className="underwriting_question-section-main-item-select-option-add-btn"
                            onClick={() => {
                              setCurrSelectOptionInput("");
                              handleAddOptionToSelectQuestion(
                                sIndex,
                                qIndex,
                                currSelectOptionInput
                              );
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="underwriting_question-section-main-item-answ">
                  {questionItem.eleType === "boolean" && (
                    <div
                      className={`custom-input-container ${
                        isSubmit &&
                        (questionItem.value === null ||
                          questionItem.value === undefined)
                          ? "error"
                          : ""
                      }`}
                    >
                      <Switch
                        checked={questionItem.value}
                        onChange={(checked) =>
                          handleChangeField(sIndex, qIndex, "value", checked)
                        }
                      />
                      {isSubmit &&
                        (questionItem.value === null ||
                          questionItem.value === undefined) && (
                          <span className="error-message">
                            This field is required
                          </span>
                        )}
                    </div>
                  )}

                  {questionItem.eleType === "string" && (
                    <div
                      className={`custom-input-container ${
                        isSubmit &&
                        (!questionItem.value || questionItem.value === "")
                          ? "error"
                          : ""
                      }`}
                    >
                      <Input
                        size="large"
                        value={questionItem.value}
                        onChange={(evt) =>
                          handleChangeField(
                            sIndex,
                            qIndex,
                            "value",
                            evt.target.value
                          )
                        }
                      />
                      {isSubmit &&
                        (!questionItem.value || questionItem.value === "") && (
                          <span className="error-message">
                            This field is required
                          </span>
                        )}
                    </div>
                  )}
                  {questionItem.eleType === "number" && (
                    <div
                      className={`custom-input-container ${
                        isSubmit &&
                        (questionItem.value === "" ||
                          questionItem.value === null ||
                          isNaN(questionItem.value))
                          ? "error"
                          : ""
                      }`}
                    >
                      <Input
                        size="large"
                        value={questionItem.value}
                        onChange={(evt) =>
                          handleChangeField(
                            sIndex,
                            qIndex,
                            "value",
                            evt.target.value
                          )
                        }
                      />
                      {isSubmit &&
                        (questionItem.value === "" ||
                          questionItem.value === null ||
                          isNaN(questionItem.value)) && (
                          <span className="error-message">
                            A valid number is required
                          </span>
                        )}
                    </div>
                  )}

                  {questionItem.eleType === "select" && (
                    <div
                      className={`custom-input-container ${
                        isSubmit &&
                        (!questionItem.value || questionItem.value === "")
                          ? "error"
                          : ""
                      }`}
                    >
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          handleChangeField(sIndex, qIndex, "value", value)
                        }
                        value={questionItem.value}
                        options={questionItem.options || []}
                      />
                      {isSubmit &&
                        (!questionItem.value || questionItem.value === "") && (
                          <span className="error-message">
                            Please select an option
                          </span>
                        )}
                    </div>
                  )}

                  {questionItem.eleType === "paragraph" && (
                    <div
                      className={`custom-input-container ${
                        isSubmit &&
                        (!questionItem.value || questionItem.value === "")
                          ? "error"
                          : ""
                      }`}
                    >
                      <TextArea
                        placeholder="Add back office 'Email Response' here…"
                        autoSize={{ minRows: 4 }}
                        size="large"
                        value={questionItem.value}
                        onChange={(evt) =>
                          handleChangeField(
                            sIndex,
                            qIndex,
                            "value",
                            evt.target.value
                          )
                        }
                      />
                      {isSubmit &&
                        (!questionItem.value || questionItem.value === "") && (
                          <span className="error-message">
                            This field is required
                          </span>
                        )}
                    </div>
                  )}
                </div>

                {/* <div className="underwriting_question-section-main-item-correct">
                  {questionItem.eleType === "boolean" && (
                    <Switch
                      checked={questionItem.correctValue}
                      onChange={(checked) =>
                        handleChangeField(
                          sIndex,
                          qIndex,
                          "correctValue",
                          checked
                        )
                      }
                    />
                  )}
                  {questionItem.eleType === "string" && (
                    <Input
                      size="large"
                      value={questionItem.correctValue}
                      onChange={(evt) =>
                        handleChangeField(
                          sIndex,
                          qIndex,
                          "correctValue",
                          evt.target.value
                        )
                      }
                    />
                  )}
                  {questionItem.eleType === "number" && (
                    <Input
                      size="large"
                      value={questionItem.correctValue}
                      onChange={(evt) =>
                        handleChangeField(
                          sIndex,
                          qIndex,
                          "correctValue",
                          evt.target.value
                        )
                      }
                    />
                  )}
                </div> */}
                {editable && allowChangeQuestions && (
                  <div className="underwriting_question-section-main-item-type">
                    <Select
                      size="large"
                      value={questionItem.eleType}
                      style={{ width: "100%" }}
                      onChange={(value) =>
                        handleChangeField(sIndex, qIndex, "eleType", value)
                      }
                    >
                      <Option value="number">Percent</Option>
                      <Option value="boolean">Boolean</Option>
                      <Option value="string">Text</Option>
                      <Option value="select">Select</Option>
                      <Option value="paragraph">Email Response</Option>
                    </Select>
                  </div>
                )}
                {editable && allowChangeQuestions && (
                  <div className="underwriting_question-section-main-item-delete-btn">
                    <DeleteOutlined
                      className="class_code-action-del"
                      onClick={() => handleDeleteQuestion(sIndex, qIndex)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="underwriting_question-section-footer">
            {editable && allowChangeQuestions && (
              <>
                <a
                  className="underwriting_question-section-footer-delete"
                  onClick={() => handleDeleteSection(sIndex)}
                >
                  Delete Section
                </a>
                <Button
                  size="large"
                  className="underwriting_question-section-footer-add"
                  type="default"
                  onClick={() => handleAdd(sIndex)}
                >
                  Add Question
                </Button>
              </>
            )}
          </div>
        </div>
      ))}
      <div className="underwriting_question-footer">
        {editable && allowChangeQuestions && (
          <Button
            size="large"
            className="underwriting_question-footer-add"
            type="default"
            onClick={() => handleAddSection()}
          >
            Add Section
          </Button>
        )}
        {!hideSaveButton && (
          <Button size="large" type="primary" onClick={handleSave}>
            Save
          </Button>
        )}
      </div>
      <Loading loading={loading} />
    </div>
  );
}
