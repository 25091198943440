import React from "react";
import { Modal } from "antd";
import ZeePOCEditForm from "@components/forms/franchisee/zee-poc-edit";


export default function POCEdit ({
  isOpen,
  onClose,
  selectedContact,
  zeeForm,
  selectedContactIndex,
  handleChange,
  handleSave,
  franchiseeData
}) {

  function handleSubmit() {
    zeeForm.resetFields();
    handleSave();
    onClose();
  }

  return (
    <Modal
      title={<span className="modal-title">Edit User Contact</span>}
      className="poc-edit"
      open={isOpen}
      footer={null}
      onCancel={onClose}
    >
      <ZeePOCEditForm
        form={zeeForm}
        onSubmit={handleSubmit}
        selectedContact={selectedContact}
        closeModal={onClose}
        handleChange={handleChange}
        index={selectedContactIndex}
        franchiseeData={franchiseeData}
      />
    </Modal>
  )
}
