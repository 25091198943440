import React from "react";
import { Modal, Button } from "antd";
import "./style.scss";

export default function POCDelete ({
  isOpen,
  onClose,
  handleDelete,
  selectedContact,
  zeeForm,
  selectedContactIndex,
  handleChange,
  handleSave,
}) {

  function handleDeleteUser() {
    handleDelete();
  }

  return (
    <Modal
      title="Delete User Contact"
      open={isOpen}
      footer={null}
      onCancel={onClose}
    >
      <div className="delete-modal">
        <p>Are you sure you want to delete this user?</p>
        <div className="delete-modal__buttons">
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleDeleteUser} type="primary" danger>Delete</Button>
        </div>
      </div>
    </Modal>
  )
}
