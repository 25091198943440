import { format } from "date-fns";

// Function to format the updatedAt date
function formatDate(date) {
  return format(new Date(date), 'MM-yyyy');
};

// Function to transform individual status data
function transformData(data, formattedDate) {
  return Object.entries(data).map(([status, count]) => ({
    status,
    count,
    date: formattedDate, // Use 'Today' if it's the current month, otherwise formatted date
  }));
};

// Main function that just transforms the data and returns the transformed results
function transformStatusData(statusData) {
  return statusData.map((status) => {
    const createdAt = status?.createdAt;
    const formattedDate = formatDate(createdAt);

    // Check if `status?.details` exists, otherwise use `status?.detail`
    const details = status?.details || status?.detail;

    return {
      complianceData: transformData(details?.complianceStatusData, formattedDate),
      policyData: transformData(details?.policyStatusData, formattedDate),
    };
  });
}


// Helper function to sort data by date
function sortByDate(dataArray) {
  const sortedData = dataArray.sort((a, b) => {
    // Convert MM-YYYY to YYYY-MM format for proper parsing
    const dateA = new Date(a.date.split("-").reverse().join("-"));
    const dateB = new Date(b.date.split("-").reverse().join("-"));
    return dateA - dateB; // Ascending order
  });

  return sortedData;
}

export { transformStatusData, sortByDate, formatDate };

/**
 * This module provides functions to transform and format status data.
 *
 * Functions:
 *
 * 1. formatDate(date):
 *    - Formats a given date to 'MM-yyyy' format.
 *    - Parameters:
 *      - date (string | Date): The date to format.
 *    - Returns:
 *      - (string): The formatted date.
 *
 * 2. transformData(data, formattedDate, createdAt):
 *    - Transforms individual status data by mapping over the entries and adding additional fields.
 *    - Parameters:
 *      - data (object): The status data to transform.
 *      - formattedDate (string): The formatted date to include in the transformed data.
 *      - createdAt (string | Date): The creation date to include in the transformed data.
 *    - Returns:
 *      - (Array): An array of transformed status data objects.
 *
 * 3. transformStatusData(statusData):
 *    - Transforms an array of status data objects by formatting dates and transforming individual status data.
 *    - Parameters:
 *      - statusData (Array): The array of status data objects to transform.
 *    - Returns:
 *      - (Array): An array of transformed status data objects.
 *
 * 4. sortByDate(dataArray):
 *    - Sorts an array of data objects by date in ascending order.
 *    - Parameters:
 *      - dataArray (Array): The array of data objects to sort.
 *    - Returns:
 *      - (Array): The sorted array of data objects.
 *
 * Usage:
 *
 * import { transformStatusData, sortByDate, formatDate } from './admin';
 *
 * const formattedDate = formatDate('2023-10-01');
 * const transformedData = transformStatusData(statusData);
 * const sortedData = sortByDate(transformedData);
 */
