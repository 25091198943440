import React from 'react'
import { Modal } from "antd";
import ZeeInsInfoEditForm from '@components/forms/franchisee/zee-ins-info-edit';
import "./style.scss";

export default function InsuredInfoEdit({ isOpen, onClose, onChange, data, handleSave }) {
  return (
    <Modal
    title={<span className="modal-title">Edit Insured Information</span>}
      className="insured-info-edit"
      // style={{ fontWeight: "none" }}
      open={isOpen}
      footer={null}
      onCancel={onClose}
    >
      <ZeeInsInfoEditForm
        onClose={onClose}
        handleSave={handleSave}
        onChange={onChange}
        data={data}
      />
    </Modal>
  )
}
