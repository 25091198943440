import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import {
  Table,
  Button,
  Dropdown,
  Menu,
  Modal,
  Tabs,
  Select,
  Input,
  Checkbox,
} from "antd";
import {
  RightOutlined,
  DownOutlined,
  CheckOutlined,
  EllipsisOutlined,
  UserAddOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useAppState } from "@context";
import { AddFranchiseeForm, UploadFranchiseeForm, Loading } from "@components";
import { useAuth0 } from "@auth0/auth0-react";
import { post } from "@utils/axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
  DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
  DO_NOT_CONTACT_OPTION_IS_LEAD,
  DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
  DO_NOT_CONTACT_OPTION_NOT_OPEN,
  DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
  DO_NOT_CONTACT_ALL,
  DO_NOT_CONTACT_LABEL_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_LABEL_INACTIVE_ZEE,
  DO_NOT_CONTACT_LABEL_IS_LEAD,
  DO_NOT_CONTACT_LABEL_NOT_OPEN,
  DO_NOT_CONTACT_LABEL_NOT_COVERAGE,
  DO_NOT_CONTACT_LABEL_CANADA_BUSINESS,
  DO_NOT_CONTACT_LABEL_CANCEL_POLICY,
  DO_NOT_CONTACT_LABEL_ALL,
  STATUS_EXPIRATION_NINETY,
  STATUS_EXPIRATION_SIXTY,
  STATUS_EXPIRATION_THIRTY,
  STATUS_EXPIRATION_TODAY,
  STATUS_EXPIRATION_NONE,
  STATUS_EXPIRATION_NINETY_LABEL,
  STATUS_EXPIRATION_SIXTY_LABEL,
  STATUS_EXPIRATION_THIRTY_LABEL,
  STATUS_EXPIRATION_TODAY_LABEL,
  STATUS_EXPIRATION_NONE_LABEL,
  INSURANCE_STATUS_COMPLIANT,
  INSURANCE_STATUS_NON_COMPLIANT,
  INSURANCE_STATUS_PENDING,
  INSURANCE_STATUS_ALL,
  EXPIRATION_OPTION,
  CATEGORY_OPTION,
  DO_NOT_CONTACT_OPTION,
  TIME_PERIOD_OPTION,
  CATEGORY_ALL,
  TIME_PERIOD_ALL,
  TIME_PERIOD_ONE_MONTH,
  TIME_PERIOD_TWO_MONTH,
  TIME_PERIOD_THREE_MONTH,
} from "@assets/const/status";
import {
  getInsuranceData,
  getIsExpired,
  getPolicyExpired,
  getRenewalPolicyStatus,
} from "@utils/common";
import { addDays, isValid, addMonths } from "date-fns";
import Card from "@components/card";
import { VIEW_MODE_UPLOAD_FORM } from "@assets/const/ui";
import { format } from "date-fns";

const { Search } = Input;

const STATUS_OPTION = [
  {
    value: INSURANCE_STATUS_COMPLIANT,
    label: "Compliant",
  },
  {
    value: INSURANCE_STATUS_NON_COMPLIANT,
    label: "Non-Compliant",
  },
  {
    value: INSURANCE_STATUS_PENDING,
    label: "Pending",
  },
  {
    value: INSURANCE_STATUS_ALL,
    label: "All",
  },
];

const MODAL_TYPE_NONE = "MODAL_TYPE_NONE";
const MODAL_TYPE_ADD = "MODAL_TYPE_ADD";

const TAB_ADD = "TAB_ADD";
const TAB_UPLOAD = "TAB_UPLOAD";

const { TabPane } = Tabs;

export default function Franchisees() {
  const { franchisorAdmin } = useAppState();
  const [contactList, setContactList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchStr, setSearchStr] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(MODAL_TYPE_NONE);
  const [modalTitle, setModalTitle] = useState("");
  const [optionCategory, setOptionCategory] = useState(CATEGORY_ALL);
  const [filterTimePeriod, setFilterTimePeriod] = useState(TIME_PERIOD_ALL);
  const [checkContact, setCheckContact] = useState(false);
  const [filterDoNotContact, setFilterDoNotContact] = useState([
    DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
    DO_NOT_CONTACT_OPTION_IS_LEAD,
    DO_NOT_CONTACT_OPTION_NOT_OPEN,
    DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
    DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
    DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
    DO_NOT_CONTACT_ALL,
  ]);
  const [optionPolicyDate, setOptionPolicyDate] = useState(
    STATUS_EXPIRATION_NONE
  );
  const [insuranceStatus, setInsuranceStatus] = useState(INSURANCE_STATUS_ALL);
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const [uploadViewMode, setUploadViewMode] = useState(VIEW_MODE_UPLOAD_FORM);

  useEffect(() => {
    handleFetchContacts();
  }, [franchisorAdmin]);

  useEffect(() => {
    const paramQuery = new URLSearchParams(location.search);
    if (paramQuery.get("filterDoNotContact")) {
      setFilterDoNotContact(paramQuery.get("filterDoNotContact"));
    }

    if (paramQuery.get("optionPolicyDate")) {
      setOptionPolicyDate(paramQuery.get("optionPolicyDate"));
    }

    if (paramQuery.get("insuranceStatus")) {
      setInsuranceStatus(paramQuery.get("insuranceStatus"));
    }
  }, [location]);

  async function handleFetchContacts() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      let resultContacts = await post(
        `contacts-list`,
        { requirementId: franchisorAdmin._id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (resultContacts.data.success) {
        setContactList(resultContacts.data.data);
      }
    } catch (e) {}
    setLoading(false);
  }

  async function handleRemoveFranchisee() {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      let resultContactsDelete = await post(
        `contact-delete`,
        {
          ids: checkedList.map((item) => item.key),
          requirementId: franchisorAdmin._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (resultContactsDelete.status === 200) {
        setContactList(resultContactsDelete.data.data);
        setCheckedList([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  function handleClose() {
    setModalType(MODAL_TYPE_NONE);
    setModalOpen(false);
    setUploadViewMode(VIEW_MODE_UPLOAD_FORM);
  }

  function getPocContactLength(data) {
    if (data.isInsureDiff) {
      if (data.insuranceData?.email) {
        return (data.poc_contacts || []).length + 1;
      }

      return (data.poc_contacts || []).length;
    } else {
      if (data?.email) {
        return (data?.poc_contacts || []).length + 1;
      }

      return (data.poc_contacts || []).length;
    }
  }

  function handleFilterDoNotContact(value) {
    setFilterDoNotContact(value);
  }

  function handleChangeDate(value) {
    setOptionPolicyDate(value);
  }

  function handleUpload() {
    setModalType(MODAL_TYPE_ADD);
    setModalOpen(true);
    setModalTitle("Franchisee");
  }

  function handleNavDetail(id) {
    history.push({
      pathname: `/dashboard/franchisees/${id}`,
    });
  }

  function isExpired(data, option) {
    let expirationPeriod = 0;
    if (option === STATUS_EXPIRATION_THIRTY) {
      expirationPeriod = 30;
    }
    if (option === STATUS_EXPIRATION_SIXTY) {
      expirationPeriod = 60;
    }
    if (option === STATUS_EXPIRATION_NINETY) {
      expirationPeriod = 90;
    }
    if (option === STATUS_EXPIRATION_TODAY) {
      expirationPeriod = 0;
    }

    if (
      data?.finalData?.commercial_general_liability_expire_date &&
      isValid(
        new Date(data?.finalData?.commercial_general_liability_expire_date)
      ) &&
      new Date(data?.finalData?.commercial_general_liability_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.automotive_liability_expire_date &&
      isValid(new Date(data?.finalData?.automotive_liability_expire_date)) &&
      new Date(data?.finalData?.automotive_liability_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.workers_compensation_expire_date &&
      isValid(new Date(data?.finalData?.workers_compensation_expire_date)) &&
      new Date(data?.finalData?.workers_compensation_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.umbrella_subrogration_expire_date &&
      isValid(new Date(data?.finalData?.umbrella_subrogration_expire_date)) &&
      new Date(data?.finalData?.umbrella_subrogration_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.crime_expire_date &&
      isValid(new Date(data?.finalData?.crime_expire_date)) &&
      new Date(data?.finalData?.crime_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.garage_expire_date &&
      isValid(new Date(data?.finalData?.garage_expire_date)) &&
      new Date(data?.finalData?.garage_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.epli_expire_date &&
      isValid(new Date(data?.finalData?.epli_expire_date)) &&
      new Date(data?.finalData?.epli_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.cyber_expire_date &&
      isValid(new Date(data?.finalData?.cyber_expire_date)) &&
      new Date(data?.finalData?.cyber_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.pro_liab_expire_date &&
      isValid(new Date(data?.finalData?.pro_liab_expire_date)) &&
      new Date(data?.finalData?.pro_liab_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.pol_liab_expire_date &&
      isValid(new Date(data?.finalData?.pol_liab_expire_date)) &&
      new Date(data?.finalData?.pol_liab_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    if (
      data?.finalData?.im_expire_date &&
      isValid(new Date(data?.finalData?.im_expire_date)) &&
      new Date(data?.finalData?.im_expire_date) <
        addDays(new Date(), expirationPeriod)
    ) {
      return true;
    }

    return false;
  }

  const dataSource = useMemo(() => {
    if (contactList && contactList.length > 0) {
      const listFranchisee = contactList
        .filter((item) => {
          if (optionPolicyDate === STATUS_EXPIRATION_NONE) return true;
          return getPolicyExpired(
            item,
            {
              [STATUS_EXPIRATION_THIRTY]: 30,
              [STATUS_EXPIRATION_SIXTY]: 60,
              [STATUS_EXPIRATION_NINETY]: 90,
              [STATUS_EXPIRATION_TODAY]: 0,
            }[optionPolicyDate]
          );
        })
        .filter((item) => {
          if (optionCategory === CATEGORY_ALL) {
            return true;
          }

          if (
            item?.status !== INSURANCE_STATUS_COMPLIANT &&
            item?.detail?.status == optionCategory
          ) {
            return true;
          }

          return false;
        })
        .filter((item) => {
          const entity = getInsuranceData(item);
          if (
            searchStr === "" ||
            !searchStr ||
            (searchStr === "null" && !entity?.entity_name) ||
            `${entity.first_name} ${entity.last_name} ${entity.entity_name} ${entity.email} ${entity.telephone} ${entity.centerName}`
              .toLowerCase()
              .includes(searchStr.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
        .filter((item) => {
          if (!checkContact) {
            return true;
          }

          if (
            checkContact &&
            (item.contact_liability ||
              item.contact_auto ||
              item.contact_work_comp ||
              item.contact_umbrella ||
              item.contact_crime ||
              item.contact_garage ||
              item.contact_epli ||
              item.contact_cyber ||
              item.contact_pro_liab ||
              item.contact_pol_liab ||
              item.contact_im ||
              item.contact_pro_ins ||
              item.contact_additional_information)
          ) {
            return true;
          }

          return false;
        })
        .filter((item) => {
          if (filterDoNotContact.includes(DO_NOT_CONTACT_ALL)) return true;
          return filterDoNotContact.includes(item.doNotContact);
        })
        .filter((item) => {
          if (filterTimePeriod === TIME_PERIOD_ALL) {
            return true;
          }

          if (
            filterTimePeriod === TIME_PERIOD_ONE_MONTH &&
            item.timePeriod &&
            new Date(item.timePeriod) < addMonths(new Date(), 1)
          ) {
            return true;
          }

          if (
            filterTimePeriod === TIME_PERIOD_TWO_MONTH &&
            item.timePeriod &&
            new Date(item.timePeriod) < addMonths(new Date(), 2)
          ) {
            return true;
          }

          if (
            filterTimePeriod === TIME_PERIOD_THREE_MONTH &&
            item.timePeriod &&
            new Date(item.timePeriod) < addMonths(new Date(), 3)
          ) {
            return true;
          }

          return false;
        })
        .map((item) => {
          const entity = getInsuranceData(item);
          const activityRequest = item.activities.filter(
            (activity) => activity.action === "STATUS_EMAIL"
          );
          const activityUpload = item.activities.filter(
            (activity) => activity.action === "STATUS_UPLOAD"
          );
          const activityConfirm = item.activities.filter(
            (activity) => activity.action === "STATUS_APPROVE"
          );

          return {
            ...item,
            ...entity,
            key: item._id,
            info: [
              {
                type: "Certificate of Insurance Confirmed",
                date:
                  activityConfirm.length > 0
                    ? moment(
                        activityConfirm[activityConfirm.length - 1].date
                      ).format("MM/DD/YYYY")
                    : "-",
                status: activityConfirm.length > 0 ? "success" : "pending",
              },
              {
                type: "Certificate Uploaded",
                date:
                  activityUpload.length > 0
                    ? moment(
                        activityUpload[activityUpload.length - 1].date
                      ).format("MM/DD/YYYY")
                    : "-",
                status: activityUpload.length > 0 ? "success" : "pending",
              },
              {
                type: "Certificate Request Sent",
                date:
                  activityRequest.length > 0
                    ? moment(
                        activityRequest[activityRequest.length - 1].date
                      ).format("MM/DD/YYYY")
                    : "-",
                status: activityRequest.length > 0 ? "success" : "pending",
              },
            ],
            location_count: (item.locationList || []).length,
            office_count: (item.officeList || []).length,
            poc_contact_count: getPocContactLength(item),
            uploadCount: item?.fileUpload?.length || 0,
            rikorCustomer:
              item?.doNotContact === DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER
                ? "Yes"
                : "No",
            totalScore: (item?.coverageScore?.totalScore || 0).toFixed(2),
            renewalPolicyStatus: getRenewalPolicyStatus(item, franchisorAdmin),
          };
        })
        .sort((itemA, itemB) => itemB.uploadCount - itemA.uploadCount);

      return listFranchisee;
    }
    return [];
  }, [
    contactList,
    searchStr,
    optionPolicyDate,
    optionCategory,
    checkContact,
    filterDoNotContact,
    filterTimePeriod,
  ]);

  function renderStatus(status) {
    // eslint-disable-next-line default-case
    switch (status) {
      case "Non-Compliant":
        return <div className="status-container danger">{status}</div>;
      case "Compliant":
        return <div className="status-container success">{status}</div>;
      case "Pending":
        return <div className="status-container info">{status}</div>;
    }
  }

  function renderExpand(record) {
    return (
      <div className="expand-container" style={{ margin: 0 }}>
        <div className="expand-header">
          <div className="header-item">Status</div>
          <div className="header-item">Date</div>
          <div className="header-item">Activity</div>
        </div>

        <div className="expand-content">
          {record.info.map((info) => (
            <div className="content-item">
              <div className="item-status">
                {info.status === "success" ? (
                  <CheckOutlined className="icon-tick" />
                ) : (
                  <EllipsisOutlined className="icon-dot" />
                )}
              </div>
              <div className="item-date">{info.date}</div>
              <div className="item-activity">{info.type}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderModal() {
    return (
      <Modal
        className="custom-modal_upload"
        title={modalTitle}
        open={modalOpen}
        footer={null}
        onOk={handleClose}
        onCancel={handleClose}
      >
        {modalType === MODAL_TYPE_ADD && (
          <Tabs defaultActiveKey={TAB_ADD}>
            <TabPane tab="Add Franchisee" key={TAB_ADD}>
              <AddFranchiseeForm
                requirementId={franchisorAdmin._id}
                host={franchisorAdmin.host}
                onOk={() => {
                  setModalOpen(false);
                  setModalType(MODAL_TYPE_NONE);
                  handleFetchContacts();
                }}
                onCancel={() => {
                  setModalOpen(false);
                }}
              />
            </TabPane>
            <TabPane tab="Upload Franchisee" key={TAB_UPLOAD}>
              <UploadFranchiseeForm
                host={franchisorAdmin.host}
                viewMode={uploadViewMode}
                setViewMode={setUploadViewMode}
                requirementId={franchisorAdmin._id}
                handleNavigate={() => {
                  setModalOpen(false);
                  setModalType(MODAL_TYPE_NONE);
                  handleFetchContacts();
                }}
              />
            </TabPane>
          </Tabs>
        )}
      </Modal>
    );
  }

  const columns = [
    {
      title: "Center Name",
      dataIndex: "centerName",
      key: "centerName",
      sorter: (a, b) => {
        return a?.centerName?.localeCompare(b?.centerName);
      },
    },
    {
      title: "Entity Name",
      dataIndex: "entity_name",
      key: "entity_name",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => {
        return a?.first_name?.localeCompare(b?.first_name);
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => {
        return a?.last_name?.localeCompare(b?.last_name);
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => {
        return a?.email?.localeCompare(b?.email);
      },
    },

    {
      title: "Rikor Customer",
      dataIndex: "rikorCustomer",
      key: "rikorCustomer",
      filters: [
        {
          text: "Yes",
          value: "Yes",
        },
        {
          text: "No",
          value: "No",
        },
      ],
      onFilter: (value, record) => record.rikorCustomer === value,
    },
    {
      title: "Average Compliance Score",
      dataIndex: "totalScore",
      key: "totalScore",
      render: (data) => <div>{data} %</div>,
      sorter: (a, b) => {
        return Number(a.totalScore) > Number(b.totalScore) ? 1 : -1;
      },
    },
    franchisorAdmin
      ? {
          title: "Policy Status",
          dataIndex: "renewalPolicyStatus",
          key: "renewalPolicyStatus",
          filters: [
            {
              text: "Lapsed",
              value: "Lapsed",
            },
            {
              text: "Expiring",
              value: "Expiring",
            },
            {
              text: "Active",
              value: "Active",
            },
            {
              text: "Not-Verified",
              value: "Not-Verified",
            },
          ],
          onFilter: (value, record) => record.renewalPolicyStatus === value,
        }
      : {},
    // {
    //   title: "Telephone",
    //   dataIndex: "telephone",
    //   key: "telephone",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
      filters: [
        {
          text: "Non-Compliant",
          value: "Non-Compliant",
        },
        {
          text: "Compliant",
          value: "Compliant",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div>{text ? format(new Date(text), "MM/dd/yyyy hh:mm a") : ""}</div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <div className="zees">
      <Card
        title="Franchisees"
        extra={() => (
          <div className="zees-action">
            <Button type="primary" onClick={handleUpload}>
              <UserAddOutlined /> Add Franchisee
            </Button>

            <Button
              type="primary"
              danger
              disabled={!checkedList || checkedList.length === 0 ? true : false}
              onClick={handleRemoveFranchisee}
            >
              Delete
            </Button>
          </div>
        )}
      >
        <div className="zees_filter">
          <div className="zees_filter-item">
            <Search
              placeholder="Franchisee name, email, or entity"
              value={searchStr}
              onChange={(evt) => setSearchStr(evt.target.value)}
              enterButton="Search Franchisees"
            />
          </div>
          <div className="zees_filter-action">
            <Button type="primary" onClick={() => setShowFilter(!showFilter)}>
              <FilterOutlined />
            </Button>
          </div>
        </div>
        {showFilter && (
          <div className="zee_list-action-content">
            <div className="zee_list-action-content-main">
              <div>
                <div>
                  <label>
                    <b>Record Status</b>
                  </label>
                </div>
                <Select
                  mode="multiple"
                  style={{ minWidth: "150px", maxWidth: "250px" }}
                  placeholder="Record Status"
                  onChange={(val) => setFilterDoNotContact(val)}
                  options={DO_NOT_CONTACT_OPTION}
                  value={filterDoNotContact}
                />
              </div>
              <div>
                <div>
                  <label>
                    <b>Policy Date</b>
                  </label>
                </div>
                <Select
                  value={optionPolicyDate}
                  onChange={setOptionPolicyDate}
                  options={EXPIRATION_OPTION}
                />
              </div>
              <div>
                <div>
                  <label>
                    <b>Status</b>
                  </label>
                </div>
                <Select
                  value={optionCategory}
                  onChange={setOptionCategory}
                  options={CATEGORY_OPTION}
                />
              </div>
              {/* <div>
                <div>
                  <label>
                    <b>Timer</b>
                  </label>
                </div>
                <Select
                  style={{ minWidth: "100px" }}
                  placeholder="Timer"
                  onChange={(val) => setFilterTimePeriod(val)}
                  options={TIME_PERIOD_OPTION}
                  value={filterTimePeriod}
                />
              </div> */}
              <div>
                <div>
                  <label>
                    <b>Contact ASAP</b>
                  </label>
                </div>
                <Checkbox
                  checked={checkContact}
                  onChange={(evt) => setCheckContact(evt.target.checked)}
                />
              </div>
            </div>
            <div className="zee_list-action-content-footer"></div>
          </div>
        )}

        <div className="zees-con">
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={dataSource}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleNavDetail(record.key);
                },
              };
            }}
            expandable={{
              expandedRowRender: renderExpand,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <RightOutlined
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onExpand(record, e);
                    }}
                  />
                ) : (
                  <DownOutlined
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onExpand(record, e);
                    }}
                  />
                ),
            }}
          />
        </div>
      </Card>
      {renderModal()}
      <Loading loading={loading} />
    </div>
  );
}
