import React, { useState, useEffect } from "react";
import { useAppState } from "@context/index";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "@utils/axios";
import "./style.scss";

import POCContact from "../../admin-board/franchisee-detail/poc-contact";

export default function FranchiseeAddUser() {
  const { authSetting, franchisorAdmin } = useAppState();
  const { getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleLoadFranchisee = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${authSetting?.user?.contactId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFranchiseeData(result.data.data || {});
    } catch (error) {
      console.error("Error loading franchisee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authSetting?.user?.contactId) {
      handleLoadFranchisee();
    }
  }, [authSetting]);

  if (loading || !franchiseeData) {
    return "";
  }

  return (
    <div className="add-user">
      <POCContact
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
        franchisorData={franchisorAdmin}
        openOnMount={true}
      />
    </div>
  );
}
