import Alert from "@components/alert";
import { post, put, get } from "@utils/axios";
import { getHostTag } from "@utils/host";
import { AutoComplete, Button, Checkbox, Spin, message } from "antd";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { extractAPIErrMsg, notify } from "@utils/common";
import CountDown from "./count-down";
import { convertTimeStrToMilli } from "@utils/time";
import SuccessPage from "./success-page";
import EmailVerification from "./email-verification";
import {
  DO_NOT_CONTACT_OPTION_IS_LEAD,
  USER_TYPE_FRANCHISEE
} from "@assets/const/status";
import QuotePOCBlock from "./quote-poc-block";
import QuoteEntityBlock from "./quote-entity-block";
import QuoteLocationBlock from "./quote-location-block";
import QuoteBuildOutBlock from "./quote-build-out-block";
import QuoteEquipmentBlock from "./quote-equipment-block";
import QuoteVehicleBlock from "./quote-vehicle-block";
import { AUTH0_CONNECTION_EMAIL } from "@assets/const/auth";

export default function AddFranchisee({ franchisors }) {
  const [selectedFranchisor, setSelectedFranchisor] = useState({});
  const [foundFranchisee, setFoundFranchisee] = useState();
  const [lookingUpFranchisee, setLookingUpFranchisee] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyEmail, setCompanyEmail] = useState("");
  const [pocFormVal, setPocFormVal] = useState([]);
  const [entityFormVal, setEntityFormVal] = useState({});
  const [locFormVal, setLocFormVal] = useState([]);
  const [buildFormVal, setBuildFormVal] = useState({});
  const [equipFormVal, setEquipFormVal] = useState([]);
  const [unEquipFormVal, setUnEquipFormVal] = useState([]);
  const [vehicleFormVal, setVehicleFormVal] = useState([]);
  const [driverFormVal, setDriverFormVal] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [applicationSucceeded, setApplicationSucceeded] = useState(false);
  const [tempAccessToken, setTempAccessToken] = useState({
    data: "",
    expiresIn: ""
  });
  const [subscribeToEmailUpdates, setSubscribeToEmailUpdates] = useState(false);
  const [subscribeToTextUpdates, setSubscribeToTextUpdates] = useState(false);
  const [consultantData, setConsultantData] = useState(null);

  useEffect(() => {
    if (foundFranchisee) {
      handlePrefillForm();
    }
  }, [foundFranchisee]);

  useEffect(() => {
    if (selectedFranchisor?.consultantId && tempAccessToken.data !== "") {
      handleLoadConsultant();
    }
  }, [selectedFranchisor, tempAccessToken]);

  async function handleLoadConsultant() {
    try {
      const resultConsultant = await get(
        `user/${selectedFranchisor?.consultantId}`,
        {
          headers: {
            "x-internal-api-access-token": tempAccessToken.data
          }
        }
      );

      setConsultantData(resultConsultant?.data?.data);
    } catch (error) {}
  }

  function resetVerification() {
    setEmailVerified(false);
    resetForms();
  }

  function resetForms() {
    setFoundFranchisee();
  }

  const expiresInMillis = useMemo(
    () =>
      tempAccessToken.expiresIn
        ? convertTimeStrToMilli(tempAccessToken.expiresIn)
        : 0,
    [tempAccessToken.expiresIn]
  );

  const options = useMemo(
    () =>
      franchisors
        .filter((f) => f.dba)
        .sort((itemA, itemB) => itemA.dba.localeCompare(itemB.dba))
        .map((f, index) => ({
          key: index,
          value: `${f.dba}`,
          data: f
        })),
    []
  );

  function handlePrefillForm() {
    setEntityFormVal(
      foundFranchisee?.isInsureDiff
        ? {
            entity_name: foundFranchisee?.insuranceData?.entity_name,
            entity_type: foundFranchisee?.insuranceData?.entity_type,
            dba: foundFranchisee?.insuranceData?.dba,
            opening_date: foundFranchisee?.insuranceData?.opening_date,
            tax_id: foundFranchisee?.insuranceData?.tax_id,
            centerName: foundFranchisee?.insuranceData?.centerName,
            annualRevenue: foundFranchisee?.insuranceData?.annualRevenue
          }
        : {
            entity_name: foundFranchisee?.entity_name,
            entity_type: foundFranchisee?.entity_type,
            dba: foundFranchisee?.dba,
            opening_date: foundFranchisee?.opening_date,
            tax_id: foundFranchisee?.tax_id,
            centerName: foundFranchisee?.centerName,
            annualRevenue: foundFranchisee?.annualRevenue
          }
    );
    setPocFormVal(foundFranchisee?.poc_contacts || []);
    setLocFormVal(foundFranchisee?.commonLocList || []);
    setBuildFormVal(foundFranchisee?.buildOutInfo || {});
    setEquipFormVal(foundFranchisee?.equipmentList || []);
    setUnEquipFormVal(foundFranchisee?.unScheEquipList || []);
    setVehicleFormVal(foundFranchisee?.vehicleList || []);
    setDriverFormVal(foundFranchisee?.driverList || []);
  }
  async function handleFranchisorSelect(selectedOption) {
    if (!_.isEqual(selectedOption.data, selectedFranchisor)) {
      resetVerification();
    }
    setSelectedFranchisor(selectedOption.data);
  }

  async function handleLookupFranchisee(email, tempAccessToken) {
    try {
      setLookingUpFranchisee(true);
      const fetchResults = await Promise.all([
        lookupFranchiseePromise(
          {
            email,
            requirementId: selectedFranchisor._id
          },
          tempAccessToken
        ),
        lookupFranchiseePromise(
          {
            "insuranceData.email": email,
            requirementId: selectedFranchisor._id
          },
          tempAccessToken
        )
      ]);
      for (const result of fetchResults) {
        if (result.status === 200) {
          if (result.data?.data.length > 0) {
            const franchisee = result.data.data[0];
            setFoundFranchisee(franchisee);
            return;
          }
        } else {
          resetForms();

          throw new Error(`Received a non-200 status code: ${result.status}`);
        }
      }

      setPocFormVal([
        {
          email: companyEmail
        }
      ]);
    } catch (error) {
      const errMsg = `Failed to look up franchisee with email ${email} - ${extractAPIErrMsg(
        error,
        "please contact support."
      )}.`;
      console.log(error);
      notify("Error", errMsg);
    } finally {
      setLookingUpFranchisee(false);
    }
  }

  async function lookupFranchiseePromise(query, tempAccessToken) {
    return post(
      `admin/franchisee`,
      {
        host: getHostTag(),
        franchisorId: selectedFranchisor._id,
        query
      },
      {
        headers: {
          "x-internal-api-access-token": tempAccessToken
        }
      }
    );
  }

  async function handleVerifyCode(verificationCode) {
    try {
      const result = await post("/auth0/verify-email-code", {
        email: companyEmail,
        code: verificationCode
      });
      setTempAccessToken({
        data: result.data.data.tempAccessToken,
        expiresIn: result.data.data.expiresIn
      });
      setEmailVerified(true);
      return result.data.data.tempAccessToken;
    } catch (error) {
      const errMsg = `Wrong verification code ${verificationCode} - ${extractAPIErrMsg(
        error,
        "please contact support if you think this is a mistake."
      )}.`;
      setEmailVerified(false);
      console.log(error);
      notify("Error", errMsg);
    }
  }

  async function handleQuoteNow() {
    setIsSubmit(true);
    if (getValidation()) {
      return;
    }
    setIsSubmitting(true);
    try {
      if (_.isEmpty(foundFranchisee)) {
        await createNewFranchisee();
      } else {
        await updateFranchisee();
      }
      setApplicationSucceeded(true);
      notify(
        "Success!",
        "You'll be receiving an email very soon to proceed with the quoting process!",
        "success"
      );
    } catch (error) {
      const errMsg = `Failed to quote - ${extractAPIErrMsg(
        error,
        "please contact support"
      )}.`;
      console.log("failed to update/create Franchisee data:", error);
      notify("Error", errMsg);
    }
    setIsSubmitting(false);
  }

  async function updateFranchisee() {
    await put(
      `contact/${foundFranchisee._id}`,
      {
        ...(foundFranchisee.isInsureDiff
          ? {
              insuranceData: {
                ...foundFranchisee.insuranceData,
                ...entityFormVal
              }
            }
          : {
              ...entityFormVal,
              email: companyEmail.toLowerCase()
            }),
        poc_contacts: pocFormVal,
        commonLocList: locFormVal,
        buildOutInfo: buildFormVal,
        equipmentList: equipFormVal,
        unScheEquipList: unEquipFormVal,
        vehicleList: vehicleFormVal,
        driverList: driverFormVal,
        doNotContact: DO_NOT_CONTACT_OPTION_IS_LEAD,
        quoteStatus: true,
        subscribeToEmailUpdates,
        subscribeToTextUpdates,
        host: selectedFranchisor?.host || getHostTag()
      },
      {
        headers: {
          "x-internal-api-access-token": tempAccessToken.data
        }
      }
    );
  }

  function getValidation() {
    const entityFields = [
      { key: "entity_name", label: "Entity Name" },
      { key: "entity_type", label: "Entity Type" },
      { key: "dba", label: "DBA" },
      { key: "tax_id", label: "Tax ID" }
    ];

    const pocFields = [
      { key: "firstName", label: "First Name" },
      { key: "lastName", label: "Last Name" },
      { key: "email", label: "Email" },
      { key: "telephone", label: "Phone Number" }
    ];

    const locFields = [
      { key: "address", label: "Location" },
      { key: "city", label: "City" },
      { key: "state", label: "State" },
      { key: "zip", label: "Zip" },
      { key: "isBilling", label: "Billing Checkbox" },
      { key: "isMailing", label: "Mailing Checkbox" },
      { key: "isPhysical", label: "Physical Checkbox" }
    ];

    for (const field of entityFields) {
      if (!entityFormVal?.[field.key] || entityFormVal?.[field.key] === "") {
        message.error(`${field.label} is required!`);
        return true;
      }
    }

    for (const item of pocFormVal || []) {
      for (const field of pocFields) {
        if (!item?.[field.key] || item?.[field.key] === "") {
          message.error(`${field.label} is required!`);
          return true;
        }
      }
    }

    for (const location of locFormVal || []) {
      let hasAtLeastOneCheckbox = false;
      for (const field of locFields) {
        if (["isBilling", "isMailing", "isPhysical"].includes(field.key)) {
          hasAtLeastOneCheckbox =
            hasAtLeastOneCheckbox || location?.[field.key] === true;
        } else if (!location?.[field.key] || location?.[field.key] === "") {
          message.error(`${field.label} is required!`);
          return true;
        }
      }

      if (!hasAtLeastOneCheckbox) {
        message.error(
          "At least one checkbox (Billing, Mailing, or Physical) must be selected!"
        );
        return true;
      }
    }

    return false;
  }

  async function createNewFranchisee() {
    const param = {
      ...entityFormVal,
      email: companyEmail,
      poc_contacts: pocFormVal,
      commonLocList: locFormVal,
      buildOutInfo: buildFormVal,
      equipmentList: equipFormVal,
      unScheEquipList: unEquipFormVal,
      vehicleList: vehicleFormVal,
      driverList: driverFormVal,
      requirementId: selectedFranchisor._id,
      doNotContact: DO_NOT_CONTACT_OPTION_IS_LEAD,
      quoteStatus: true,
      subscribeToEmailUpdates,
      subscribeToTextUpdates,
      host: selectedFranchisor?.host || getHostTag(),
      createdBy: companyEmail.toLowerCase()
    };
    const result = await post(`contact`, param, {
      headers: {
        "x-internal-api-access-token": tempAccessToken.data
      }
    });

    const entity = result?.data?.entity;
    try {
      await post(
        `admin/user/create`,
        {
          first_name: pocFormVal[0].firstName,
          last_name: pocFormVal[0].lastName,
          email: companyEmail,
          connection: AUTH0_CONNECTION_EMAIL,
          name: companyEmail,
          type: USER_TYPE_FRANCHISEE,
          permission: "",
          host: getHostTag(),
          requirementId: selectedFranchisor._id,
          contactId: entity._id,
          createdBy: companyEmail
        },
        {
          headers: {
            "x-internal-api-access-token": tempAccessToken.data
          }
        }
      );
    } catch (error) {}
  }

  function renderFranchiseeFoundAlert() {
    const franchiseeFound = !_.isEmpty(foundFranchisee);
    if (!franchiseeFound) {
      return <></>;
    }
    return (
      <div className="alert-confirmation-line">
        <div>
          <Alert
            status="success"
            message={`Franchisee with email ${companyEmail} found in Rikor system.`}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        {applicationSucceeded ? (
          <SuccessPage calDiscoverLink={consultantData?.link} />
        ) : (
          <div className="franchisor-selection">
            {tempAccessToken.expiresIn && emailVerified && (
              <div className="session-expiration-alert">
                <CountDown
                  initialValueInMillis={expiresInMillis}
                  onCountDownEnd={resetVerification}
                />
              </div>
            )}
            <p>Which franchisor is this for?</p>
            <AutoComplete
              size="large"
              style={{ width: 500 }}
              options={options}
              allowClear={true}
              placeholder="Search for the franchisor you're looking for"
              onClear={() => {
                setSelectedFranchisor({});
              }}
              onChange={(value) => {
                if (!value) {
                  setSelectedFranchisor({});
                }
              }}
              onSelect={(_, option) => {
                handleFranchisorSelect(option);
              }}
              filterOption={(inputValue, option) => {
                if (option && option.value) {
                  return (
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue?.toUpperCase()) !== -1
                  );
                }
                return false;
              }}
            />

            {!_.isEmpty(selectedFranchisor) && (
              <>
                {selectedFranchisor.personal_infor_logo && (
                  <div className="img-container">
                    <img
                      className="img-container-img"
                      src={selectedFranchisor.personal_infor_logo}
                    />
                  </div>
                )}

                <div className="franchisee-form">
                  <EmailVerification
                    key={selectedFranchisor._id}
                    emailVerified={emailVerified}
                    companyEmail={companyEmail}
                    onChange={setCompanyEmail}
                    onVerify={async (code) => {
                      const tempAccessToken = await handleVerifyCode(code);
                      if (tempAccessToken) {
                        await handleLookupFranchisee(
                          companyEmail,
                          tempAccessToken
                        );
                      }
                    }}
                    onReset={resetVerification}
                  />
                  {lookingUpFranchisee && (
                    <div className="looking-up-franchisee-spin">
                      <Spin />
                    </div>
                  )}
                  {emailVerified && (
                    <>
                      {renderFranchiseeFoundAlert()}
                      <div>
                        <QuotePOCBlock
                          data={pocFormVal}
                          onChange={setPocFormVal}
                          franchiseeData={foundFranchisee}
                          setFranchiseeData={setFoundFranchisee}
                          customToken={tempAccessToken}
                          isSubmit={isSubmit}
                        />
                        <QuoteEntityBlock
                          data={entityFormVal}
                          onChange={setEntityFormVal}
                          isSubmit={isSubmit}
                        />
                        <QuoteLocationBlock
                          data={locFormVal}
                          onChange={setLocFormVal}
                          isSubmit={isSubmit}
                        />
                        <QuoteBuildOutBlock
                          data={buildFormVal}
                          onChange={setBuildFormVal}
                        />
                        <QuoteEquipmentBlock
                          equipData={equipFormVal}
                          onChangeEquipData={setEquipFormVal}
                          unEquipData={unEquipFormVal}
                          onChangeUnEquipData={setUnEquipFormVal}
                          locData={locFormVal}
                        />
                        <QuoteVehicleBlock
                          vehicleData={vehicleFormVal}
                          driverData={driverFormVal}
                          onChangeVehicle={setVehicleFormVal}
                          onChangeDriver={setDriverFormVal}
                        />

                        <hr />
                        <div className="footer">
                          <div>
                            <Checkbox
                              checked={subscribeToEmailUpdates}
                              onChange={(e) => {
                                setSubscribeToEmailUpdates(e.target.checked);
                              }}
                            >
                              Yes, please email me urgent insurance status
                              updates and alerts.
                            </Checkbox>
                          </div>
                          <div>
                            <Checkbox
                              checked={subscribeToTextUpdates}
                              onChange={(e) => {
                                setSubscribeToTextUpdates(e.target.checked);
                              }}
                            >
                              Yes, please allow Rikor to send me SMS text
                              messages.
                            </Checkbox>
                          </div>
                          <div style={{ marginTop: 10 }}>
                            Message and data rates may apply. Reply HELP for
                            help. Reply STOP to unsubscribe from SMS messages.
                            Up to 3 messages notifying the user of insurance
                            lapse or expiring policy and other essential
                            notifications per month.
                          </div>
                          <div>
                            View our <a href="/terms">terms & conditions</a> and{" "}
                            <a href="/privacy">privacy policy</a>.
                          </div>
                          <div>
                            <Button
                              className="quote-now-btn"
                              type="primary"
                              onClick={handleQuoteNow}
                            >
                              {isSubmitting ? <Spin /> : "Quote now!"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
