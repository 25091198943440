import { useEffect, useState } from "react";
import { Card, Loading } from "../../../../components";
import { Checkbox, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "../../../../utils/axios";
import { isUndefined } from "lodash";
import FormProfile from "../../admin-insurance-quote/components/form-profile";
import { notify, validateSpecialInputFields } from "@utils/common";

import InsuredInfoEdit from "@components/modals/insured-info-edit";
import "./style.scss";

export default function InsuredInfor(props) {
  const { franchiseeData } = props;
  const { getAccessTokenSilently, user } = useAuth0();

  const [isInsureDiff, setIsInsureDiff] = useState(
    isUndefined(franchiseeData?.isInsureDiff)
      ? true
      : franchiseeData?.isInsureDiff
  );
  const [loading, setLoading] = useState(false);
  const [insuredEditOpen, setInsuredEditOpen] = useState(false);
  const [formVal, setFormVal] = useState(handleGetFormVal());
  const [logInsuranceData, setLogInsuranceData] = useState(
    franchiseeData?.logInsuranceData || []
  );

  useEffect(() => {
    setIsInsureDiff(
      isUndefined(franchiseeData?.isInsureDiff)
        ? true
        : franchiseeData?.isInsureDiff
    );
    setFormVal(handleGetFormVal());
    setLogInsuranceData(franchiseeData?.logInsuranceData || []);
  }, [franchiseeData]);

  function handleGetFormVal() {
    if (
      franchiseeData?.insuranceData &&
      Object.keys(franchiseeData.insuranceData).length > 0 &&
      Object.values(franchiseeData.insuranceData).some((value) => value !== "")
    ) {
      return { ...franchiseeData.insuranceData };
    }

    return {
      first_name: franchiseeData?.first_name || "",
      last_name: franchiseeData?.last_name,
      entity_name: franchiseeData?.entity_name,
      entity_type: franchiseeData?.entity_type ?? "CP",
      address: franchiseeData?.address,
      email: franchiseeData?.email,
      city: franchiseeData?.city,
      state: franchiseeData?.state,
      zip: franchiseeData?.zip,
      telephone: franchiseeData?.telephone,
      opening_date:
        franchiseeData?.opening_date || franchiseeData?.detail?.openingDate,
      tax_id:
        franchiseeData?.tax_id ||
        franchiseeData?.detail?.fimEntity?.fimTtTaxpayer,
      dba: franchiseeData?.dba,
      centerName: franchiseeData?.centerName,
    };
  }

  function isEditOpen() {
    setInsuredEditOpen(true);
  }

  function handleCheckSame(evt) {
    setIsInsureDiff(evt.target.checked);
  }

  async function handleSave(formData) {
    // const err = validateSpecialInputFields(formVal);
    // if (err) {
    //   notify("Form Invalid", err, "error");
    //   return;
    // }
    setLoading(true);
    logInsuranceData.push({
      updatedBy: user.email,
      updatedAt: new Date(),
      data: formData,
    });
    try {
      const param = {
        insuranceData: formData,
        logInsuranceData,
        isInsureDiff: true,
      };
      const token = await getAccessTokenSilently();
      const result = await put(`contact/${franchiseeData._id}`, param, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLogInsuranceData([...logInsuranceData]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Card
      title="Insured Information"
      className="insured_information"
      extra={() => (
        <div className="insured_information-action">
          <EditOutlined onClick={isEditOpen} style={{ fontSize: "20px" }} />
          {/* <Checkbox checked={isInsureDiff} onChange={handleCheckSame}>
            Is Different?
          </Checkbox>
          <Button onClick={handleSave} type="primary" size="large">
            Save
          </Button> */}
        </div>
      )}
    >
      {isInsureDiff && <FormProfile data={formVal} onChange={setFormVal} />}
      <Loading loading={loading} />
      <InsuredInfoEdit
        handleSave={handleSave}
        onChange={setFormVal}
        isOpen={insuredEditOpen}
        data={formVal}
        onClose={() => setInsuredEditOpen(false)}
      />
    </Card>
  );
}
