import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  Drawer,
  Dropdown,
  Menu,
  Tabs,
  Input,
  notification,
  Modal,
  Popconfirm,
  Empty,
  Checkbox,
} from "antd";

import {
  RightOutlined,
  CheckOutlined,
  PlusOutlined,
  UserAddOutlined,
  DownOutlined,
  UpOutlined,
  FilterOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import { PERMISSION_TABLE, ACCESS_FULL } from "@utils/permission";
import {
  getInsuranceData,
  getIsExpired,
  getPolicyExpired,
  getRenewalPolicyStatus,
} from "@utils/common";
import ReactJson from "react-json-view";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import { deleteAPI, post, postDoc, put } from "@utils/axios";
import { useAppState } from "@context";
import { getHostTag } from "@utils/host";
import {
  Loading,
  Card,
  AddFranchiseeForm,
  UploadFranchiseeForm,
} from "@components";
import {
  STATUS_EXPIRATION_NINETY,
  STATUS_EXPIRATION_SIXTY,
  STATUS_EXPIRATION_THIRTY,
  STATUS_EXPIRATION_TODAY,
  STATUS_EXPIRATION_NONE,
  STATUS_EXPIRATION_NINETY_LABEL,
  STATUS_EXPIRATION_SIXTY_LABEL,
  STATUS_EXPIRATION_THIRTY_LABEL,
  STATUS_EXPIRATION_TODAY_LABEL,
  STATUS_EXPIRATION_NONE_LABEL,
  INSURANCE_STATUS_COMPLIANT,
  CATEGORY_DEVELOPMENT,
  CATEGORY_ACTIVE,
  CATEGORY_TERMINATED,
  CATEGORY_ALL,
  DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
  DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
  DO_NOT_CONTACT_OPTION_IS_LEAD,
  DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
  DO_NOT_CONTACT_OPTION_NOT_OPEN,
  DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
  DO_NOT_CONTACT_ALL,
  DO_NOT_CONTACT_LABEL_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_LABEL_INACTIVE_ZEE,
  DO_NOT_CONTACT_LABEL_IS_LEAD,
  DO_NOT_CONTACT_LABEL_NOT_OPEN,
  DO_NOT_CONTACT_LABEL_NOT_COVERAGE,
  DO_NOT_CONTACT_LABEL_CANADA_BUSINESS,
  DO_NOT_CONTACT_LABEL_CANCEL_POLICY,
  DO_NOT_CONTACT_LABEL_ALL,
  TIME_PERIOD_ALL,
  TIME_PERIOD_ONE_MONTH,
  TIME_PERIOD_TWO_MONTH,
  TIME_PERIOD_THREE_MONTH,
  EXPIRATION_OPTION,
  CATEGORY_OPTION,
  DO_NOT_CONTACT_OPTION,
  TIME_PERIOD_OPTION,
} from "@assets/const/status";
import {
  VIEW_MODE_UPLOAD_FORM,
  VIEW_MODE_CONTACT_LIST,
} from "@assets/const/ui";
import { addMonths, format } from "date-fns";
import moment from "moment";
import MergeFranchisee from "../merge-franchisee";
import "./style.scss";
import { APP_DATE_FORMAT_DATEFNS } from "@assets/const/form";
import { batchRun } from "@utils/common";

const { TabPane } = Tabs;

const MODAL_TYPE_NONE = "MODAL_TYPE_NONE";
const MODAL_TYPE_UPLOAD = "MODAL_TYPE_UPLOAD";
const MODAL_TYPE_ADD = "MODAL_TYPE_ADD";
const MODAL_TYPE_MERGE = "MODAL_TYPE_MERGE";
const MODAL_TYPE_STATUS = "MODAL_TYPE_STATUS";

const { Search } = Input;
const { Option } = Select;

export default function ZeeList(props) {
  const {
    franchiseeList,
    franchisorList,
    setFranchiseeList,
    setFranchisorList,
    franchisorAdmin,
    setFranchisorAdmin,
    authSetting,
  } = useAppState();
  // Hooks
  const location = useLocation();
  let history = useHistory();
  const paramQuery = new URLSearchParams(location.search);

  // Custom
  const [uploadViewMode, setUploadViewMode] = useState(VIEW_MODE_UPLOAD_FORM);
  const [searchStr, setSearchStr] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [checkContact, setCheckContact] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [checkedList, setCheckedList] = useState([]);
  const [detailFlag, setDetailFlag] = useState(false);
  const [selectedFranchisor, setSelectedFranchisor] = useState("");
  const [selectedDetail, setSelectedDetail] = useState({});
  const [massStatus, setMassStatus] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(MODAL_TYPE_NONE);
  const [modalTitle, setModalTitle] = useState("");
  const [optionPolicyDate, setOptionPolicyDate] = useState(
    STATUS_EXPIRATION_NONE
  );

  const [optionCategory, setOptionCategory] = useState(CATEGORY_ALL);
  const [filterDoNotContact, setFilterDoNotContact] = useState([
    DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
    DO_NOT_CONTACT_OPTION_IS_LEAD,
    DO_NOT_CONTACT_OPTION_NOT_OPEN,
    DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
    DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
    DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
    DO_NOT_CONTACT_ALL,
  ]);
  const [filterTimePeriod, setFilterTimePeriod] = useState(TIME_PERIOD_ALL);

  useEffect(() => {
    handleLoadFranchisor();
  }, []);

  useEffect(() => {
    setSearchStr(paramQuery.get("searchStr"));
  }, [location]);

  useEffect(() => {
    handleLoadDataAPI();
    handleLoadInit();
  }, [franchisorAdmin]);

  const displaySelectedZeesAmount =
    checkedList.length > 0 ? `${checkedList.length} selected` : "";

  const modalStyle =
    modalType === MODAL_TYPE_STATUS
      ? "custom-modal_status"
      : "custom-modal_upload";

  function getPocContactLength(data) {
    if (data.isInsureDiff) {
      if (data.insuranceData?.email) {
        return (data.poc_contacts || []).length + 1;
      }

      return (data.poc_contacts || []).length;
    } else {
      if (data?.email) {
        return (data?.poc_contacts || []).length + 1;
      }

      return (data.poc_contacts || []).length;
    }
  }

  function handleSearch(evt) {
    setSearchStr(evt.target.value);
  }

  function handleFranchisorChange(evt) {
    const franchisor = franchisorList.find((item) => item._id === evt);
    setFranchisorAdmin(franchisor);
  }

  async function handleMerge() {
    setModalOpen(true);
    setModalTitle("Merge Franchisees");
    setModalType(MODAL_TYPE_MERGE);
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  async function handleDownload(evt, filePath) {
    evt.stopPropagation();
    evt.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const fileNameArr = filePath.split("/");
      const fileName = fileNameArr[fileNameArr.length - 1];
      const extensionArr = fileName.split(".");
      const extension = extensionArr[extensionArr.length - 1];
      postDoc(
        `download`,
        {
          fileName: fileName,
          envMode: process.env.REACT_APP_DOC_UPLOAD_MODE,
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `document.${extension}`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      handleNotification(
        "Failed",
        "Unable to download. Please contact to admin directly."
      );
      console.log("error", err);
    }
  }

  function handleChangeStatus() {
    setModalType(MODAL_TYPE_STATUS);
    setModalOpen(true);
    setModalTitle("Change Status");
  }

  function handleUpload() {
    setModalType(MODAL_TYPE_UPLOAD);
    setModalOpen(true);
    setModalTitle("Franchisee");
  }

  // Need to remove later --- start

  async function handleResetCoverage() {
    const token = await getAccessTokenSilently();
    await Promise.all(
      checkedList.map(async (item) => {
        try {
          await post(
            `contact/${item._id}/reset-coverage`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } catch (error) {
          console.log("reset franchisee coverage error -", error);
        }
      })
    );
    handleLoadDataAPI();
  }

  // Need to remove later --- end

  async function handleRemove() {
    const token = await getAccessTokenSilently();
    await Promise.all(
      checkedList.map(async (item) => {
        try {
          const result = await deleteAPI(`contact/${item._id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } catch (error) {
          console.log("delete franchisee error -", error);
        }
      })
    );
    handleLoadDataAPI();
  }

  function handleNavDetail(id) {
    history.push({
      pathname: `franchisees/${id}`,
    });
  }

  function handleLoadInit() {
    if (franchisorAdmin?._id) {
      setSelectedFranchisor(franchisorAdmin._id);
    } else {
      setSelectedFranchisor("");
    }
  }

  async function handleLoadDataAPI() {
    setLoading(true);
    try {
      await handleLoadFranchisee(franchisorAdmin?._id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  async function handleLoadFranchisee(requirementId) {
    try {
      let params = { host: getHostTag() };
      if (requirementId) {
        params = {
          ...params,
          franchisorId: requirementId,
        };
      }
      const token = await getAccessTokenSilently();
      const result = await post(`admin/franchisee`, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.status === 200) {
        const franchiseeData = result.data.data;
        const tempObj = {};
        if (franchiseeData.length > 0) {
          // franchiseeData.forEach((item) => {
          //   tempObj[item.email.toLowerCase()] = item;
          // });
          setFranchiseeList(franchiseeData);
        } else {
          setFranchiseeList([]);
        }
      }
    } catch (err) {
      handleNotification(
        "Failed",
        "Franchisee loading is failed. Please contact to admin directly."
      );
    }
  }

  async function handleLoadFranchisor() {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/franchisor`,
        { host: getHostTag() },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setFranchisorList(result.data.requirements);
      }
    } catch (err) {}
  }

  function handleClose() {
    setModalType(MODAL_TYPE_NONE);
    setModalOpen(false);
    setUploadViewMode(VIEW_MODE_UPLOAD_FORM);
  }

  async function handleStatusUpdate(key, value) {
    if (value) {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const param = {
          [key]: value === "all" ? null : value, // non-rikor customer returns all for some reason. look into this.
        };
        // make a batch of batches taht contain 10 values per batch. for loop on the batches and promise.all on each batch
        await batchRun(
          checkedList.map(
            (zee) => () =>
              put(`contact/${zee._id}`, param, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }).catch((error) => console.error(error))
          ),
          10
        );

        setLoading(false);
        setModalOpen(false);
        setCheckedList([]);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    }
  }

  function renderStatus(status) {
    // eslint-disable-next-line default-case
    switch (status) {
      case "Non-Compliant":
        return <div className="status-container danger">{status}</div>;
      case "Compliant":
        return <div className="status-container success">{status}</div>;
      case "Pending":
        return <div className="status-container info">{status}</div>;
    }
  }

  function renderSectionStatus(status) {
    // eslint-disable-next-line default-case
    switch (status) {
      case "Non-Compliant":
        return <div className="status-container danger">{status}</div>;
      case "Compliant":
        return <div className="status-container success">{status}</div>;
      case "Pending":
        return <div className="status-container info">{status}</div>;
    }

    return <div className="status-container"></div>;
  }

  function renderModal() {
    return (
      <Modal
        className={modalStyle}
        title={modalTitle}
        open={modalOpen}
        footer={null}
        onOk={handleClose}
        onCancel={handleClose}
      >
        {modalType === MODAL_TYPE_STATUS && (
          <div>
            <Select
              // mode="multiple"
              style={{ width: "250px", marginRight: "10px" }}
              placeholder="Record Status"
              onChange={(val) => setMassStatus(val)}
              options={DO_NOT_CONTACT_OPTION}
              value={massStatus}
            />
            <Popconfirm
              title="Are you sure to update the status?"
              onConfirm={() => handleStatusUpdate("doNotContact", massStatus)}
              onCancel={() => console.log("cancle remove")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" className="save-btn">
                Save
              </Button>
            </Popconfirm>
          </div>
        )}
        {modalType === MODAL_TYPE_UPLOAD && (
          <Tabs
            defaultActiveKey={MODAL_TYPE_ADD}
            onChange={(key) => console.log("active key ---", key)}
          >
            <TabPane tab="Add Franchisee" key={MODAL_TYPE_ADD}>
              <AddFranchiseeForm
                requirementId={franchisorAdmin?._id}
                host={franchisorAdmin.host}
                onOk={() => {
                  setModalOpen(false);
                  setModalType(MODAL_TYPE_NONE);
                  handleLoadDataAPI();
                }}
                onCancel={() => {
                  setModalOpen(false);
                }}
              />
            </TabPane>
            <TabPane tab="Upload Franchisee" key={MODAL_TYPE_UPLOAD}>
              <UploadFranchiseeForm
                host={franchisorAdmin.host}
                viewMode={uploadViewMode}
                setViewMode={setUploadViewMode}
                requirementId={franchisorAdmin._id}
                handleNavigate={() => {
                  setModalOpen(false);
                  setModalType(MODAL_TYPE_NONE);
                  handleLoadDataAPI();
                }}
              />
            </TabPane>
          </Tabs>
        )}

        {modalType === MODAL_TYPE_MERGE && (
          <MergeFranchisee
            left={checkedList[0]}
            right={checkedList[1]}
            onClose={() => {
              setModalOpen(false);
              setModalType(MODAL_TYPE_NONE);
              handleLoadDataAPI();
            }}
          />
        )}
      </Modal>
    );
  }

  function renderExpand(record) {
    if ((record?.poc_contacts || []).length === 0) {
      return <Empty description="No Contacts"></Empty>;
    }
    return (
      <div className="expand-container" style={{ margin: 0 }}>
        <div className="expand-header">
          <div className="header-item">First Name</div>
          <div className="header-item">Last Name</div>
          <div className="header-item">Email</div>
          <div className="header-item">Telephone</div>
          <div className="header-item">Primary</div>
          <div className="header-item">Active</div>
        </div>

        <div className="expand-content">
          {record.poc_contacts.map((info, infoIndex) => (
            <div key={`key-info-${infoIndex}`} className="content-item">
              <div className="item-date">{info.firstName}</div>
              <div className="item-date">{info.lastName}</div>
              <div className="item-date">{info.email}</div>
              <div className="item-date">{info.telephone}</div>
              <div className="item-status">
                {info.isPrimary ? (
                  <CheckOutlined className="icon-tick" />
                ) : (
                  <div></div>
                )}
              </div>
              <div className="item-status">
                {info.isActive ? (
                  <CheckOutlined className="icon-tick" />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const actionDisable = useMemo(() => checkedList.length === 0, [checkedList]);
  const mergeDisable = useMemo(() => checkedList.length < 2, [checkedList]);

  const actionMenu = (
    <Menu>
      <Menu.Item disabled={mergeDisable} onClick={handleMerge}>
        Merge
      </Menu.Item>
      {/*
        TODO: remove this later - this is just a temporary measure to allow
        us to reset coverage details and compliance status while we're
        developing. --- start
      */}
      <Menu.Item disabled={actionDisable} onClick={handleResetCoverage}>
        Reset Coverage
      </Menu.Item>
      {/* TODO: remove this later - this is just a temporary measure to allow
        us to reset coverage details and compliance status while we're
        developing. --- end */}
      {PERMISSION_TABLE["FRANCHISEE_DELETE"][authSetting.userPermission] ===
        ACCESS_FULL && (
        <Popconfirm
          title="Are you sure to delete these franchisees?"
          onConfirm={handleRemove}
          onCancel={() => console.log("cancle remove")}
          okText="Yes"
          cancelText="No"
        >
          <Menu.Item disabled={actionDisable}>Remove</Menu.Item>
        </Popconfirm>
      )}
      <Menu.Item disabled={actionDisable} onClick={handleChangeStatus}>
        Change Status
      </Menu.Item>
    </Menu>
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRows);
    },
  };

  const columns = [
    {
      title: "Center Name",
      dataIndex: "centerName",
      key: "centerName",
      sorter: (a, b) => {
        return a?.centerName?.localeCompare(b?.centerName);
      },
    },
    {
      title: "Entity Name",
      dataIndex: "entity_name",
      key: "entity_name",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => {
        return a?.first_name?.localeCompare(b?.first_name);
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => {
        return a?.last_name?.localeCompare(b?.last_name);
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => {
        return a?.email?.localeCompare(b?.email);
      },
    },
    {
      title: "Telephone",
      dataIndex: "telephone",
      key: "telephone",
    },
    {
      title: "Rikor Customer",
      dataIndex: "rikorCustomer",
      key: "rikorCustomer",
      filters: [
        {
          text: "Yes",
          value: "Yes",
        },
        {
          text: "No",
          value: "No",
        },
      ],
      onFilter: (value, record) => record.rikorCustomer === value,
    },
    {
      title: "Avg",
      dataIndex: "totalScore",
      key: "totalScore",
      render: (data) => <div>{data} %</div>,
      sorter: (a, b) => {
        return Number(a.totalScore) > Number(b.totalScore) ? 1 : -1;
      },
    },
    {
      title: "Policy Exp",
      dataIndex: "commercial_general_liability_expire_date",
      key: "commercial_general_liability_expire_date",
      sorter: (a, b) => {
        return new Date(a.commercial_general_liability_expire_date) >
          new Date(b.commercial_general_liability_expire_date)
          ? 1
          : -1;
      },
    },
    franchisorAdmin
      ? {
          title: "Policy Status",
          dataIndex: "renewalPolicyStatus",
          key: "renewalPolicyStatus",
          filters: [
            {
              text: "Lapsed",
              value: "Lapsed",
            },
            {
              text: "Expiring",
              value: "Expiring",
            },
            {
              text: "Active",
              value: "Active",
            },
            {
              text: "Not-Verified",
              value: "Not-Verified",
            },
          ],
          onFilter: (value, record) => record.renewalPolicyStatus === value,
        }
      : {},
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
      filters: [
        {
          text: "Non-Compliant",
          value: "Non-Compliant",
        },
        {
          text: "Compliant",
          value: "Compliant",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div>{text ? format(new Date(text), "MM/dd/yyyy") : ""}</div>
      ),
      sorter: {
        compare: (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    // {
    //   title: "Data From",
    //   dataIndex: "sourcePlatform",
    //   key: "sourcePlatform",
    //   filters: [
    //     {
    //       text: "Franconnect",
    //       value: "franconnect",
    //     },
    //     {
    //       text: "Manual",
    //       value: "manual",
    //     },
    //     {
    //       text: "None",
    //       value: "",
    //     },
    //   ],
    //   onFilter: (value, record) => record.sourcePlatform === value,
    // },
    // {
    //   title: "Locations",
    //   dataIndex: "location_count",
    //   key: "location_count",
    // },
    // {
    //   title: "Owners",
    //   dataIndex: "office_count",
    //   key: "office_count",
    // },
    // {
    //   title: "POC Contacts",
    //   dataIndex: "poc_contact_count",
    //   key: "poc_contact_count",
    // },
    // {
    //   title: "Document",
    //   dataIndex: "fileUpload",
    //   key: "fileUpload",
    //   render: (fileUpload) => {
    //     if (fileUpload && fileUpload.length > 0) {
    //       return fileUpload.map((fileItem) => (
    //         <a
    //           className="file-item-container"
    //           href={fileItem.filePath}
    //           onClick={(evt) => handleDownload(evt, fileItem.filePath)}
    //         >
    //           <img src={IconDoc} />
    //         </a>
    //       ));
    //     }
    //     return null;
    //   },
    // },
  ];

  function getMainContact(infoData) {
    if (infoData?.first_name || infoData?.last_name || infoData?.email) {
      return {
        first_name: infoData?.first_name,
        last_name: infoData?.last_name,
        email: infoData?.email,
      };
    }

    if (infoData?.isInsureDiff && infoData?.insuranceData) {
      return {
        first_name: infoData?.insuranceData?.first_name,
        last_name: infoData?.insuranceData?.last_name,
        email: infoData?.insuranceData?.email,
      };
    }

    if (infoData?.poc_contacts?.length > 0) {
      return {
        first_name: infoData.poc_contacts[0].firstName,
        last_name: infoData.poc_contacts[0].lastName,
        email: infoData.poc_contacts[0].email,
      };
    }

    return {
      first_name: "",
      last_name: "",
      email: "",
    };
  }

  const dataSource = useMemo(() => {
    if (franchiseeList && franchiseeList.length > 0) {
      const listFranchisee = franchiseeList
        .filter((item) => {
          if (optionPolicyDate === STATUS_EXPIRATION_NONE) {
            return true;
          }

          if (optionPolicyDate === STATUS_EXPIRATION_THIRTY) {
            if (getPolicyExpired(item, 30)) {
              return true;
            }
          }
          if (optionPolicyDate === STATUS_EXPIRATION_SIXTY) {
            if (getPolicyExpired(item, 60)) {
              return true;
            }
          }

          if (optionPolicyDate === STATUS_EXPIRATION_NINETY) {
            if (getPolicyExpired(item, 90)) {
              return true;
            }
          }

          if (optionPolicyDate === STATUS_EXPIRATION_TODAY) {
            if (getPolicyExpired(item, 0)) {
              return true;
            }
          }

          return false;
        })
        .filter((item) => {
          if (optionCategory === CATEGORY_ALL) {
            return true;
          }

          if (
            item?.status !== INSURANCE_STATUS_COMPLIANT &&
            item?.detail?.status == optionCategory
          ) {
            return true;
          }

          return false;
        })
        .filter((item) => {
          const entity = getInsuranceData(item);
          if (
            searchStr === "" ||
            !searchStr ||
            (searchStr === "null" && !entity?.entity_name) ||
            `${entity.first_name} ${entity.last_name} ${entity.entity_name} ${entity.email} ${entity.telephone} ${entity.centerName}`
              .toLowerCase()
              .includes(searchStr.toLowerCase())
          ) {
            return true;
          }
          return false;
        })
        .filter((item) => {
          if (!checkContact) {
            return true;
          }

          if (
            checkContact &&
            (item.contact_liability ||
              item.contact_auto ||
              item.contact_work_comp ||
              item.contact_umbrella ||
              item.contact_crime ||
              item.contact_garage ||
              item.contact_epli ||
              item.contact_cyber ||
              item.contact_pro_liab ||
              item.contact_pol_liab ||
              item.contact_im ||
              item.contact_pro_ins ||
              item.contact_additional_information)
          ) {
            return true;
          }

          return false;
        })
        .filter((item) => {
          if (
            item.doNotContact &&
            filterDoNotContact.includes(item.doNotContact)
          ) {
            return true;
          }

          if (
            !item.doNotContact &&
            filterDoNotContact.includes(DO_NOT_CONTACT_ALL)
          ) {
            return true;
          }

          return false;
        })
        .filter((item) => {
          if (filterTimePeriod === TIME_PERIOD_ALL) {
            return true;
          }

          if (
            filterTimePeriod === TIME_PERIOD_ONE_MONTH &&
            item.timePeriod &&
            new Date(item.timePeriod) < addMonths(new Date(), 1)
          ) {
            return true;
          }

          if (
            filterTimePeriod === TIME_PERIOD_TWO_MONTH &&
            item.timePeriod &&
            new Date(item.timePeriod) < addMonths(new Date(), 2)
          ) {
            return true;
          }

          if (
            filterTimePeriod === TIME_PERIOD_THREE_MONTH &&
            item.timePeriod &&
            new Date(item.timePeriod) < addMonths(new Date(), 3)
          ) {
            return true;
          }

          return false;
        })
        .map((item) => {
          const entity = getInsuranceData(item);
          const pocContacts = item?.poc_contacts || [];

          const activityRequest = item.activities.filter(
            (activity) => activity.action === "STATUS_EMAIL"
          );
          const activityUpload = item.activities.filter(
            (activity) => activity.action === "STATUS_UPLOAD"
          );
          const activityConfirm = item.activities.filter(
            (activity) => activity.action === "STATUS_APPROVE"
          );

          return {
            ...item,
            ...entity,
            first_name:
              entity?.first_name ||
              item?.first_name ||
              pocContacts[0]?.firstName,
            last_name:
              entity?.last_name || item?.last_name || pocContacts[0]?.lastName,
            key: item._id,
            info: [
              {
                type: "Certificate of Insurance Confirmed",
                date:
                  activityConfirm.length > 0
                    ? moment(
                        activityConfirm[activityConfirm.length - 1].date
                      ).format("MM/DD/YYYY")
                    : "-",
                status: activityConfirm.length > 0 ? "success" : "pending",
              },
              {
                type: "Certificate Uploaded",
                date:
                  activityUpload.length > 0
                    ? moment(
                        activityUpload[activityUpload.length - 1].date
                      ).format("MM/DD/YYYY")
                    : "-",
                status: activityUpload.length > 0 ? "success" : "pending",
              },
              {
                type: "Certificate Request Sent",
                date:
                  activityRequest.length > 0
                    ? moment(
                        activityRequest[activityRequest.length - 1].date
                      ).format("MM/DD/YYYY")
                    : "-",
                status: activityRequest.length > 0 ? "success" : "pending",
              },
            ],
            location_count: (item.locationList || []).length,
            office_count: (item.officeList || []).length,
            poc_contact_count: getPocContactLength(item),
            uploadCount: item?.fileUpload?.length || 0,
            rikorCustomer:
              item?.doNotContact === DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER
                ? "Yes"
                : "No",
            totalScore: (item?.coverageScore?.totalScore || 0).toFixed(2),
            renewalPolicyStatus: getRenewalPolicyStatus(item, franchisorAdmin),
            commercial_general_liability_expire_date: item?.finalData
              ?.commercial_general_liability_expire_date
              ? format(
                  new Date(
                    item?.finalData?.commercial_general_liability_expire_date
                  ),
                  APP_DATE_FORMAT_DATEFNS
                )
              : "",
          };
        })
        .sort((itemA, itemB) => itemB.uploadCount - itemA.uploadCount)
        .map((item) => {
          const mainContact = getMainContact(item);
          return {
            ...item,
            ...mainContact,
          };
        });

      return listFranchisee;
    }
    return [];
  }, [
    franchiseeList,
    searchStr,
    optionPolicyDate,
    optionCategory,
    checkContact,
    filterDoNotContact,
    filterTimePeriod,
  ]);

  return (
    <div className="zee_list">
      <div className="zee_list-action">
        <div className="zee_list-action-header">
          <Search
            style={{ maxWidth: 400 }}
            value={searchStr}
            onChange={handleSearch}
            placeholder="Franchisee name, email, or entity"
            enterButton="Search Franchisees"
          />
          <div className="zee_list-action-header-extra">
            <Select
              value={selectedFranchisor}
              style={{ width: 250 }}
              onChange={handleFranchisorChange}
            >
              {franchisorList &&
                franchisorList.length > 0 &&
                franchisorList.map((franchisor, franchisorIndex) => (
                  <Option
                    key={`key-franchisor-${franchisorIndex}`}
                    value={franchisor._id}
                  >
                    {franchisor.personal_infor_business_name}
                  </Option>
                ))}
            </Select>
            <Button type="primary" onClick={() => setShowFilter(!showFilter)}>
              <FilterOutlined />
            </Button>
          </div>
        </div>
        {showFilter && (
          <div className="zee_list-action-content">
            <div className="zee_list-action-content-main">
              <div>
                <div>
                  <label>
                    <b>Record Status</b>
                  </label>
                </div>
                <Select
                  mode="multiple"
                  style={{ width: "250px" }}
                  placeholder="Record Status"
                  onChange={(val) => setFilterDoNotContact(val)}
                  options={DO_NOT_CONTACT_OPTION}
                  value={filterDoNotContact}
                />
              </div>
              <div>
                <div>
                  <label>
                    <b>Policy Date</b>
                  </label>
                </div>
                <Select
                  style={{ width: "200px" }}
                  value={optionPolicyDate}
                  onChange={setOptionPolicyDate}
                  options={EXPIRATION_OPTION}
                />
              </div>
              <div>
                <div>
                  <label>
                    <b>Status</b>
                  </label>
                </div>
                <Select
                  style={{ width: "200px" }}
                  value={optionCategory}
                  onChange={setOptionCategory}
                  options={CATEGORY_OPTION}
                />
              </div>
              {/* <div>
                <div>
                  <label>
                    <b>Timer</b>
                  </label>
                </div>
                <Select
                  style={{ minWidth: "100px" }}
                  placeholder="Timer"
                  onChange={(val) => setFilterTimePeriod(val)}
                  options={TIME_PERIOD_OPTION}
                  value={filterTimePeriod}
                />
              </div> */}
              <div>
                <div>
                  <label>
                    <b>Contact ASAP</b>
                  </label>
                </div>
                <Checkbox
                  checked={checkContact}
                  onChange={(evt) => setCheckContact(evt.target.checked)}
                />
              </div>
            </div>
            <div className="zee_list-action-content-footer">
              <div className="tools-btn">
                <Dropdown overlay={actionMenu} placement="bottomLeft">
                  <Button>Action</Button>
                </Dropdown>
              </div>
              {franchisorAdmin && (
                <div className="tools-btn">
                  <Button type="primary" onClick={handleUpload}>
                    <UserAddOutlined /> Add Franchisee
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="zee_list-content">
        {displaySelectedZeesAmount}
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleNavDetail(record._id);
                // setSelectedDetail(record.detail);
                // setDetailFlag(true);
              },
            };
          }}
          expandable={{
            expandedRowRender: renderExpand,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <RightOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    onExpand(record, e);
                  }}
                />
              ) : (
                <DownOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    onExpand(record, e);
                  }}
                />
              ),
          }}
          pagination={{ defaultPageSize: 100 }}
        />
      </div>
      <Drawer
        width={720}
        title="Franchisee Detail"
        placement="right"
        closable={false}
        onClose={() => setDetailFlag(false)}
        open={detailFlag}
      >
        <ReactJson
          src={selectedDetail}
          theme="monokai"
          displayDataTypes={false}
          enableClipboard={false}
        />
      </Drawer>
      {renderModal()}
      <Loading loading={loading} />
    </div>
  );
}
