import React, { useEffect } from "react";
import { Input } from "@components";
import { Button, DatePicker } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { APP_DATE_FORMAT } from "@assets/const/form";
import { DeleteOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import "./style.scss";

export default function BlockDriver({ data, onChange, disabled, isSubmit }) {
  function handleChangeDriverInfo(index, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index][key] = value;
    onChange((prev) => newFormVal);
  }

  function handleAddNewDriver(index) {
    const newFormVal = _.cloneDeep(data);
    newFormVal.push({
      firstName: "",
      lastName: "",
      driversLicenseNumber: "",
      stateOfLicense: "",
      dateOfBirth: ""
    });
    onChange((prev) => newFormVal);
  }

  function handleDeleteDriver(index) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal.splice(index, 1);
    onChange((prev) => newFormVal);
  }

  function renderDriverInfo(index, driver) {
    return (
      <div key={`driver-${index}`} className="block_dri-item">
        <div className="form-group-five-container">
          <Input
            title="First Name"
            type="text"
            value={driver.firstName}
            required
            onChange={(evt) =>
              handleChangeDriverInfo(index, "firstName", evt.target.value)
            }
            error={isSubmit && (!driver.firstName || driver.firstName === "")}
            errorMsg="First Name is required"
          />
          <Input
            title="Last Name"
            type="text"
            value={driver.lastName}
            required
            onChange={(evt) =>
              handleChangeDriverInfo(index, "lastName", evt.target.value)
            }
            error={isSubmit && (!driver.lastName || driver.lastName === "")}
            errorMsg="Last Name is required"
          />
          <Input
            title="Driver's License"
            type="text"
            value={driver.driversLicenseNumber}
            required
            onChange={(evt) =>
              handleChangeDriverInfo(
                index,
                "driversLicenseNumber",
                evt.target.value
              )
            }
            error={
              isSubmit &&
              (!driver.driversLicenseNumber ||
                driver.driversLicenseNumber === "")
            }
            errorMsg="Driver's License Number is required"
          />
          <Input
            title="State of License"
            type="text"
            value={driver.stateOfLicense}
            required
            onChange={(evt) =>
              handleChangeDriverInfo(index, "stateOfLicense", evt.target.value)
            }
            error={
              isSubmit &&
              (!driver.stateOfLicense || driver.stateOfLicense === "")
            }
            errorMsg="State of License is required"
          />
          <div>
            <p className="input-title">Date of Birth</p>
            <DatePicker
              format={APP_DATE_FORMAT}
              size="large"
              style={{ width: "100%" }}
              value={driver.dateOfBirth ? dayjs(driver.dateOfBirth) : undefined}
              onChange={(_, dateString) =>
                handleChangeDriverInfo(index, "dateOfBirth", dateString)
              }
              className={isSubmit && !driver.dateOfBirth ? "error-log" : ""}
            />
            {isSubmit && !driver.dateOfBirth && (
              <div className="error-log">
                <span>Date of Birth is required</span>
              </div>
            )}
          </div>
        </div>
        <div className="block_dri-item-action">
          {!disabled && (
            <Button
              className="block_dri-item-action-btn"
              onClick={() => handleDeleteDriver(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="block_dri">
      {data.length === 0 && handleAddNewDriver()}
      {data.map((driver, index) => renderDriverInfo(index, driver))}
      {!disabled && (
        <div className="block_dri-footer">
          <Button
            className="block_dri-footer-link"
            onClick={handleAddNewDriver}
          >
            <UsergroupAddOutlined />
            Add Driver
          </Button>
        </div>
      )}
    </div>
  );
}
