import React, { useState, useEffect, useMemo } from "react";
import { Textarea, Loading } from "../../../../components";
import { Button, notification, Alert } from "antd";
import "./style.scss";

import { useAuth0 } from "@auth0/auth0-react";
import { put } from "../../../../utils/axios";
import { PERMISSION_TABLE } from "../../../../utils/permission";
import { useAppState } from "../../../../context";
import {
  buildCertificateValid,
  buildInsuranceCertValid,
} from "../../../../utils/build-logic";
import {
  DOC_MAX_LIMIT,
  STATUS_APPROVE_ADDITIONAL_INFORMATION,
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING,
} from "@assets/const/status";
import { mapFieldAdditionalInfo } from "@utils/form-recog";

export default function AdditionalInfoForm(props) {
  const { docIndex, franchisorData } = props;

  const { getAccessTokenSilently } = useAuth0();
  const { authSetting } = useAppState();
  const [formVal, setFormVal] = useState(handleGetFormVal());

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const formValUpdate = handleGetFormVal();
    setFormVal(formValUpdate);
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      (docIndex >= 0 &&
        docIndex === franchiseeData?.finalData?.additional_infor_doc_index) ||
      docIndex === DOC_MAX_LIMIT
    ) {
      if (
        franchiseeData?.finalData?.additional_infor_form_status ===
          STATUS_SECTION_APPROVE ||
        franchiseeData?.finalData?.additional_infor_form_status ===
          STATUS_SECTION_PENDING
      ) {
        const finalData = franchiseeData.finalData;

        return {
          additional_infor_certificate_holder:
            finalData.additional_infor_certificate_holder,
          additional_infor_operation_description:
            finalData.additional_infor_operation_description,
        };
      } else {
        return getEmptyForm();
      }
    }

    if (docIndex >= 0) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldAdditionalInfo(data);
      }
    }

    return getEmptyForm();
  }

  function getEmptyForm() {
    return {
      additional_infor_certificate_holder: "",
      additional_infor_operation_description: "",
    };
  }

  async function handleApprove() {
    let { franchiseeData, docIndex } = props;
    if (docIndex >= 0) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        let activities = franchiseeData.activities;
        if (activities) {
          activities.push({
            action: STATUS_APPROVE_ADDITIONAL_INFORMATION,
            date: new Date(),
          });
        } else {
          activities = [
            {
              action: STATUS_APPROVE_ADDITIONAL_INFORMATION,
              date: new Date(),
            },
          ];
        }

        let finalData = franchiseeData?.finalData
          ? franchiseeData.finalData
          : {};
        finalData = {
          ...finalData,
          ...formVal,
          additional_infor_form_status: STATUS_SECTION_APPROVE,
          additional_infor_doc_index: docIndex,
        };

        const result = await put(
          `contact/${franchiseeData._id}`,
          {
            finalData: finalData,
            activities: activities,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { handleUpdateForm } = props;
        handleUpdateForm(result.data.entity);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      handleNotification("Failed", "Need to check a documentation");
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  const certMatchRate = useMemo(
    () =>
      buildCertificateValid(
        [
          franchisorData.additional_infor_business_name || "",
          franchisorData.additional_infor_address || "",
          franchisorData.additional_infor_city || "",
          franchisorData.additional_infor_state || "",
          franchisorData.additional_infor_zip || "",
        ].join(" "),
        formVal.additional_infor_certificate_holder || ""
      ),
    [franchisorData, formVal]
  );
  const insuranceCertMatch = useMemo(
    () =>
      buildInsuranceCertValid(
        franchisorData.additional_infor_certificate || "",
        formVal.additional_infor_operation_description || ""
      ),
    [franchisorData, formVal]
  );

  return (
    <div className="additional-infor-content">
      <div className="form-main">
        <div className="panel-left">
          <div className="line-item-container">
            <Textarea
              title="Certificate Holder"
              type="text"
              placeholder="Certificate Holder"
              value={formVal.additional_infor_certificate_holder}
              rows={10}
              onChange={(evt) =>
                handleChange(
                  "additional_infor_certificate_holder",
                  evt.target.value
                )
              }
            />
            <Alert
              className="similar-rate"
              message={`Similarity: ${certMatchRate.similarity}% - ${
                certMatchRate.error ? "Bad" : "Good"
              }`}
              type={certMatchRate.error ? "error" : "success"}
            />
          </div>
          <div>
            {certMatchRate.error && (
              <div className="issue-report-item">{certMatchRate.msg}</div>
            )}
          </div>
        </div>
        <div className="panel-right">
          <Textarea
            title="Description of Operations"
            type="text"
            placeholder="If you require specific language on the Certificate of Insurance please add it here."
            value={formVal.additional_infor_operation_description}
            rows={10}
            onChange={(evt) =>
              handleChange(
                "additional_infor_operation_description",
                evt.target.value
              )
            }
          />
          <Alert
            className="similar-rate"
            message={`Similarity: ${insuranceCertMatch.similarity}% - ${
              insuranceCertMatch.error ? "Bad" : "Good"
            }`}
            type={insuranceCertMatch.error ? "error" : "success"}
          />
          <div>
            {insuranceCertMatch.error && (
              <div className="issue-report-item">{insuranceCertMatch.msg}</div>
            )}
          </div>
        </div>
      </div>

      <div className="form-footer">
        {PERMISSION_TABLE["FRANCHISEE_DATA_APPROVE"][
          authSetting.userPermission
        ] > 0 && (
          <Button type="primary" size="large" onClick={handleApprove}>
            Approve
          </Button>
        )}
      </div>
      <Loading loading={loading} />
    </div>
  );
}
