import React, { useState } from "react";
import { Button, Form, Input as AntInput, Radio, Select, Switch } from "antd";
import { PREFERRED_CONTACT_METHOD_LIST, ROLE_LIST } from "@assets/const/ui";
import { useAppState } from "@context/index";
import Input from "@components/input";
import { validateEmail, getPrimaryContact, handleDisableRMSInsVerChange, handleRMSComplianceChange } from "@utils/common";
import {
  TYPE_INPUT_PRIMARY,
  TYPE_INPUT_PHONE,
  TYPE_INPUT_YEAR,
  TYPE_INPUT_ADDRESS,
  TYPE_INPUT_DATE,
  TYPE_INPUT_DIGIT,
  TYPE_INPUT_TAX_ID,
  TYPE_INPUT_VIN,
  TYPE_INPUT_CURRENCY
} from "@assets/const/ui";
import "./style.scss";

export default function ZeePOCEditForm({ form, onSubmit, selectedContact, closeModal, handleChange, index, franchiseeData }) {

  const { authSetting } = useAppState();
  const [firstName, setFirstName] = useState(selectedContact.firstName);
  const [lastName, setLastName] = useState(selectedContact.lastName);
  const [email, setEmail] = useState(selectedContact.email);
  const [telephone, setTelephone] = useState(selectedContact.telephone);
  const [directLine, setDirectLine] = useState(selectedContact.directLine);
  const [preferredMethod, setPreferredMethod] = useState(selectedContact.preferredMethod);
  const [role, setRole] = useState(selectedContact.role);
  const [isPrimary, setIsPrimary] = useState(selectedContact.isPrimary);
  const [isActive, setIsActive] = useState(selectedContact.isActive);
  const [disableRMSInsVer, setDisableRMSInsVer] = useState(selectedContact.disableRMSInsVer);
  const [enableRMSCompliance, setEnableRMSCompliance] = useState(selectedContact.enableRMSCompliance);
  const [errors, setErrors] = useState({});

  function handleValidation() {
    const errObj = {};
    // remove all non-numeric characters from telephone and directLine
    const tel = telephone?.replace(/[^0-9]/g, '');
    const dirLine = directLine?.replace(/[^0-9]/g, '');

    if (!firstName || !lastName || !email) {
      return false;
    }

    // validate email
    if (!validateEmail(email)) {
      errObj.email = "Please enter a valid email address";
    } else {
      delete errors.email;
    }

    if (tel?.length > 1 && tel?.length !== 10) {
      errObj.telephone = "Please enter a valid cell phone number";
    } else {
      delete errObj.telephone;
    }

    if (dirLine?.length > 1 && dirLine?.length !== 10) {
      errObj.directLine = "Please enter a valid direct line number";
    } else {
      delete errObj.directLine;
    }
    setErrors(errObj);
    console.log(errObj);
    return Object.keys(errObj).length === 0;

  };

  function handleEmailInput(evt) {
    if (!validateEmail(evt.target.value)) {
      setErrors((prev) => ({ ...prev, email: "Please enter a valid email address" }));
    } else {
      delete errors.email;
    }

    setEmail(evt.target.value);
  };

  function handleSave() {
    const data = {
      firstName,
      lastName,
      email,
      telephone,
      directLine,
      preferredMethod,
      role,
      isPrimary,
      isActive,
      disableRMSInsVer,
      enableRMSCompliance
    };
    if(handleValidation() === false) return;

    Object.entries(data).forEach(([key, value]) => {
      handleChange(index, key, value);
    });

    setErrors({});
    onSubmit(data);
  }

  // function

  function adminSwitches() {
    if (authSetting?.userRole === "ROLE_ADMIN") {
      return (
        <div className="poc-switches">
          <div>
            <div>
              <label className="input-title">Primary:</label>
              <Form.Item>
                <Switch
                  checked={isPrimary}
                  onChange={(checked) => setIsPrimary(checked)}
                />
              </Form.Item>
            </div>
            <div>
              <label className="input-title">Active:</label>
              <Form.Item>
                <Switch
                  checked={isActive}
                  onChange={(checked) => setIsActive(checked)}
                />
              </Form.Item>
            </div>
          </div>
          <div>
            <div>
              <label className="input-title">Policy Status Verification:</label>
              <Form.Item>
                {console.log("disableRMSInsVer", disableRMSInsVer)}
                <Switch
                  checked={handleDisableRMSInsVerChange(disableRMSInsVer, getPrimaryContact(franchiseeData))}
                  onChange={(checked) => setDisableRMSInsVer(!checked)}

                />
              </Form.Item>
            </div>
            <div>
              <label className="input-title">Compliance Status Verification:</label>
              <Form.Item>
                {console.log("enableRMSCompliance", enableRMSCompliance)}
                <Switch
                  checked={handleRMSComplianceChange(enableRMSCompliance, getPrimaryContact(franchiseeData))}
                  onChange={(checked) => setEnableRMSCompliance(checked)}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      );
    }

    return null; // Return null if the condition is not met
  };


  return (
    <Form className="poc-cr" form={form} onFinish={handleSave}>
      <div className="form-group-container">
        <div>
          <label className="input-title">First Name</label>
          <Form.Item
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name." }
            ]}
          >
            <AntInput
              required={true}
              placeholder="First Name"
              size="large"
              value={firstName}
              onChange={(evt) => setFirstName(evt.target.value)}
            />
          </Form.Item>
        </div>
        <div>
          <label className="input-title">Last Name</label>
          <Form.Item
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name." }
            ]}
          >
            <AntInput
              required={true}
              placeholder="Last Name"
              size="large"
              value={lastName}
              onChange={(evt) => setLastName(evt.target.value)}
            />
          </Form.Item>
        </div>
      </div>
      <div className="form-group-container">
        <div>
          <label className="input-title">Email</label>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email." }
            ]}
          >
            <AntInput
              required={true}
              type="email"
              placeholder="Email"
              size="large"
              value={email}
              onChange={handleEmailInput}
            />
          </Form.Item>
        </div>
        <div>
          <label className="input-title">Cell Phone</label>
          <Form.Item
            name="telephone"
          >
            <Input
              style={{ fontSize: "14px" }}
              inputType={TYPE_INPUT_PHONE}
              {...{
                value: telephone,
                onChange: (evt) => setTelephone(evt.target.value),
              }}
            />
            { errors?.telephone && <span className="error-msg">{errors?.telephone}</span> }
          </Form.Item>
        </div>
      </div>
      <div className="form-group-container">
        <div>
          <label className="input-title">Direct Line</label>
          <Form.Item
            name="directLine"
          >
            <Input
              style={{ fontSize: "14px" }}
              inputType={TYPE_INPUT_PHONE}
              {...{
                value: directLine,
                onChange: (evt) => setDirectLine(evt.target.value),
              }}
            />
            { errors?.directLine && <span className="error-msg">{errors?.directLine}</span> }
          </Form.Item>
        </div>
        <div>
          <label className="input-title">Preferred Contact Method</label>
          <Form.Item
            name="preferredMethod"
            rules={[
              { message: "Please input your preferred method." }
            ]}
          >
            <Select
              placeholder="Preferred Contact Method"
              size="large"
              value={preferredMethod}
              onChange={(val) => setPreferredMethod(val)}
            >
              {PREFERRED_CONTACT_METHOD_LIST.map((item) => (
                <Select.Option value={item.value} key={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="form-group-container">
        <div>
          <label className="input-title">Role</label>
          <Form.Item>
            <Select
              style={{ width: "100%", height: "40px" }}
              value={role}
              onChange={(val) => setRole(val)}
              options={ROLE_LIST}
            />
          </Form.Item>
          <div></div>
        </div>
      </div>
      {adminSwitches()}
      <div className="poc-cr-btns form">
        <Button type="default" size="large" onClick={closeModal}>Cancel</Button>
        <Button type="primary" htmlType="submit" size="large" onClick={handleSave}>Submit</Button>
      </div>
    </Form>
  );
}
