import React, { useState, useEffect } from "react";
import { Input, Loading, Textarea, ScreenMask } from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import { usePlacesWidget } from "react-google-autocomplete";
import { getAddressItem } from "../../../utils/address";
import { useAppState } from "@context";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  SOURCE_ONBOARDING,
} from "../../../assets/const/form";
import "./style.scss";

export default function AdditionalInfoForm(props) {
  const { data, btnLabel, source, onNext, readOnly } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    additional_infor_business_name:
      sourceData?.additional_infor_business_name || "",
    additional_infor_certificate:
      sourceData?.additional_infor_certificate || "",
    additional_infor_dba: sourceData?.additional_infor_dba || "",
  });
  const [addressForm, setAddressForm] = useState({
    additional_infor_address: sourceData?.additional_infor_address || "",
    additional_infor_city: sourceData?.additional_infor_city || "",
    additional_infor_state: sourceData?.additional_infor_state || "",
    additional_infor_zip: sourceData?.additional_infor_zip || "",
  });

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      let zipCode = "",
        state = "",
        city = "";
      if (place.address_components) {
        zipCode = getAddressItem(
          place.address_components,
          "postal_code",
          "short_name"
        );
        state = getAddressItem(
          place.address_components,
          "administrative_area_level_1",
          "short_name"
        );
        city = getAddressItem(
          place.address_components,
          "locality",
          "long_name"
        );
        const street = getAddressItem(
          place.address_components,
          "street_number",
          "long_name"
        );
        const route = getAddressItem(
          place.address_components,
          "route",
          "long_name"
        );
        setAddressForm({
          ...addressForm,
          additional_infor_city: city,
          additional_infor_state: state,
          additional_infor_zip: zipCode,
          additional_infor_address: `${street} ${route}`,
        });
      }
    },
  });

  useEffect(() => {
    setFormVal({
      additional_infor_business_name:
        sourceData?.additional_infor_business_name || "",
      additional_infor_certificate:
        sourceData?.additional_infor_certificate || "",
      additional_infor_dba: sourceData?.additional_infor_dba || "",
    });
    setAddressForm({
      additional_infor_address: sourceData?.additional_infor_address || "",
      additional_infor_city: sourceData?.additional_infor_city || "",
      additional_infor_state: sourceData?.additional_infor_state || "",
      additional_infor_zip: sourceData?.additional_infor_zip || "",
    });
  }, [sourceData]);

  useEffect(() => {
    const { handleUpdateForm } = props;
    if (handleUpdateForm) {
      const data = {
        additional_infor_business_name: formVal?.additional_infor_business_name,
        additional_infor_certificate: formVal?.additional_infor_certificate,
        additional_infor_dba: formVal?.additional_infor_dba,
        additional_infor_address: addressForm?.additional_infor_address,
        additional_infor_city: addressForm?.additional_infor_city,
        additional_infor_state: addressForm?.additional_infor_state,
        additional_infor_zip: addressForm?.additional_infor_zip,
      };
      handleUpdateForm(data);
    }
  }, [formVal, addressForm]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }
  function handleChangeAddress(key, value) {
    setAddressForm({
      ...addressForm,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const param = {
        ...formVal,
        ...addressForm,
      };
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="additional-info-content">
      <div className="form-main">
        <div className="panel-left">
          <h3>
            <b>CERTIFICATE HOLDER INFORMATION</b>
          </h3>
          <div className="line-item-container">
            <Input
              title="BUSINESS NAME*"
              type="text"
              placeholder="BUSINESS NAME*"
              value={formVal.additional_infor_business_name}
              onChange={(evt) =>
                handleChange("additional_infor_business_name", evt.target.value)
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="DBA"
              type="text"
              placeholder="DBA"
              value={formVal.additional_infor_dba}
              onChange={(evt) =>
                handleChange("additional_infor_dba", evt.target.value)
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="ADDRESS*"
              type="text"
              placeholder="ADDRESS"
              ref={ref}
              inputType="TYPE_INPUT_ADDRESS"
              value={addressForm.additional_infor_address}
              onChange={(evt) =>
                handleChangeAddress(
                  "additional_infor_address",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="CITY*"
              type="text"
              placeholder="CITY"
              value={addressForm.additional_infor_city}
              onChange={(evt) =>
                handleChangeAddress("additional_infor_city", evt.target.value)
              }
            />
          </div>
          <div className="group-item-container">
            <Input
              title="STATE*"
              type="text"
              placeholder="STATE"
              value={addressForm.additional_infor_state}
              onChange={(evt) =>
                handleChangeAddress("additional_infor_state", evt.target.value)
              }
            />
            <Input
              title="ZIP*"
              type="text"
              placeholder="ZIP"
              value={addressForm.additional_infor_zip}
              onChange={(evt) =>
                handleChangeAddress("additional_infor_zip", evt.target.value)
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <h3>
            <b>DESCRIPTION OF OPERATIONS</b>
          </h3>
          <Textarea
            type="text"
            placeholder="If you require specific language on the Certificate of Insurance please add it here."
            value={formVal.additional_infor_certificate}
            rows={10}
            onChange={(evt) =>
              handleChange("additional_infor_certificate", evt.target.value)
            }
          />
        </div>
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />
      {readOnly && <ScreenMask />}
    </div>
  );
}
