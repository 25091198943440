import React from "react";
import { Input } from "@components";
import { Select, DatePicker, Tooltip } from "antd";
import { ENTITY_CODE } from "@assets/const/legal-entity";
import { APP_DATE_FORMAT } from "@assets/const/form";
import { isEmpty } from "lodash";
import { TYPE_INPUT_TAX_ID } from "@assets/const/ui";
import { usePlacesWidget } from "react-google-autocomplete";
import dayjs from "dayjs";
import { extractLocationData } from "@utils/google-map";
import { CONSTRUCTION_TYPE_CODES } from "@assets/const/hartford";
import { BUILDING_SECURITY_PROTOCOLS } from "@assets/const/building";
import "./style.scss";

const { Option } = Select;

export default function BlockBuildOut({ data, onChange, isSubmit }) {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },

    onPlaceSelected: (place) => {
      const { zip, city, state, address } = extractLocationData(
        place.address_components
      );
      onChange((prev) => ({
        ...prev,
        city: city,
        state: state,
        zip: zip,
        address: address
      }));
    }
  });

  const { ref: contractorMailingAddressRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },

    onPlaceSelected: (place) => {
      const { zip, city, state, address } = extractLocationData(
        place.address_components
      );

      onChange((prev) => ({
        ...prev,
        contractorMailingAddress: [address, city, state, zip]
          .filter(Boolean)
          .join(", ")
      }));
    }
  });

  const { ref: bankMailingAddressRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },

    onPlaceSelected: (place) => {
      const { zip, city, state, address } = extractLocationData(
        place.address_components
      );
      onChange((prev) => ({
        ...prev,
        bankMailingAddress: [address, city, state, zip]
          .filter(Boolean)
          .join(", ")
      }));
    }
  });
  function handleChange(key, value) {
    onChange((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <div className="build-out-info-container">
      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">
          Project Address
        </div>
        <Input
          title="Address"
          ref={ref}
          type="text"
          value={data.address}
          inputType="TYPE_INPUT_ADDRESS"
          required
          onChange={(evt) => handleChange("address", evt.target.value)}
          error={isSubmit && (!data.address || data.address === "")}
          errorMsg="Address is required"
        />
        <Input
          title="Line 2"
          type="text"
          value={data.address2}
          required
          onChange={(evt) => handleChange("line2", evt.target.value)}
        />
        <div className="form-group-three-container">
          <Input
            title="City"
            type="text"
            value={data.city}
            required
            onChange={(evt) => handleChange("city", evt.target.value)}
            error={isSubmit && (!data.city || data.city === "")}
            errorMsg="City is required"
          />
          <Input
            title="State"
            type="text"
            value={data.state}
            required
            onChange={(evt) => handleChange("state", evt.target.value)}
            error={isSubmit && (!data.state || data.state === "")}
            errorMsg="State is required"
          />
          <Input
            title="Zip"
            type="text"
            value={data.zip}
            required
            onChange={(evt) => handleChange("zip", evt.target.value)}
            error={isSubmit && (!data.zip || data.zip === "")}
            errorMsg="ZIP Code is required"
          />
        </div>
      </div>

      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">
          Building Info
        </div>
        <div className="form-group-four-container">
          <Tooltip title="Original year the building was built where your project is">
            <div>
              <Input
                title="Year Built"
                type="number"
                value={data.yearBuilt}
                required
                onChange={(evt) => handleChange("yearBuilt", evt.target.value)}
                error={isSubmit && (!data.yearBuilt || data.yearBuilt === "")}
                errorMsg="Year Built is required"
              />
            </div>
          </Tooltip>
          <Tooltip title="How many total square feet that the building will be occupying">
            <div>
              <Input
                title="Square Feet"
                type="number"
                value={data.squareFeet}
                required
                onChange={(evt) => handleChange("squareFeet", evt.target.value)}
                error={isSubmit && (!data.squareFeet || data.squareFeet === "")}
                errorMsg="Square Feet is required"
              />
            </div>
          </Tooltip>
          <Tooltip title="How many stories does the building have">
            <div>
              <Input
                title="# of Stories"
                type="number"
                value={data.numOfStories}
                required
                onChange={(evt) =>
                  handleChange("numOfStories", evt.target.value)
                }
                error={
                  isSubmit && (!data.numOfStories || data.numOfStories === "")
                }
                errorMsg="# of Stories is required"
              />
            </div>
          </Tooltip>
          <Input
            title="Total Build-Out Cost"
            type="number"
            value={data.buildOutCost}
            required
            onChange={(evt) => handleChange("buildOutCost", evt.target.value)}
            error={isSubmit && (!data.buildOutCost || data.buildOutCost === "")}
            errorMsg="Build-Out Cost is required"
          />
        </div>
      </div>

      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">
          Construction Info
        </div>
        <div className="form-group-four-container build-out-info-container-section-construction">
          <div>
            <div className="input-title">Construction Type</div>
            <Select
              size="large"
              options={CONSTRUCTION_TYPE_CODES}
              value={data.constructionType}
              style={{ width: "100%" }}
              onChange={(value) =>
                //Locks, Video Surveillance, Fencing, Security, Alarm, None
                handleChange("constructionType", value)
              }
            />
          </div>

          <div>
            <div className="input-title">Description of Security</div>
            <Select
              size="large"
              options={BUILDING_SECURITY_PROTOCOLS}
              style={{ width: "100%" }}
              value={data.descriptionOfSecurity}
              onChange={(value) => handleChange("descriptionOfSecurity", value)}
            />
          </div>

          <div>
            <div className="input-title">Start Date</div>
            <DatePicker
              format={APP_DATE_FORMAT}
              size="large"
              style={{ width: "100%" }}
              value={
                data.constructionStartDate
                  ? dayjs(data.constructionStartDate)
                  : undefined
              }
              onChange={(_, dateString) => {
                handleChange("constructionStartDate", dateString);
                const startData = new Date(dateString.replace(/-/g, "/"));
                const autoFillEndDate = startData.setFullYear(
                  startData.getFullYear() + 1
                );
                handleChange(
                  "constructionEstimatedEndDate",
                  dayjs(autoFillEndDate).format()
                );
              }}
            />
          </div>

          <div>
            <div className="input-title">Estimated End Date</div>
            <DatePicker
              format={APP_DATE_FORMAT}
              size="large"
              style={{ width: "100%" }}
              value={
                data.constructionEstimatedEndDate
                  ? dayjs(data.constructionEstimatedEndDate)
                  : undefined
              }
              onChange={(_, dateString) =>
                handleChange("constructionEstimatedEndDate", dateString)
              }
            />
          </div>
        </div>
      </div>

      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">
          General Contractor
        </div>
        <div className="form-group-two-container">
          <Input
            title="Business Name"
            type="text"
            value={data.contractorBusinessName}
            onChange={(evt) =>
              handleChange("contractorBusinessName", evt.target.value)
            }
          />
          <Input
            title="Mailing Address"
            type="text"
            ref={contractorMailingAddressRef}
            value={data.contractorMailingAddress}
            inputType="TYPE_INPUT_ADDRESS"
            onChange={(evt) =>
              handleChange("contractorMailingAddress", evt.target.value)
            }
          />
        </div>
      </div>

      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">Bank Info</div>
        <div className="form-group-two-container">
          <Tooltip title="Name of lender">
            <div>
              <Input
                title="Bank Name"
                type="text"
                value={data.bankName}
                onChange={(evt) => handleChange("bankName", evt.target.value)}
              />
            </div>
          </Tooltip>
          <Input
            title="Bank Mailing Address"
            type="text"
            ref={bankMailingAddressRef}
            inputType="TYPE_INPUT_ADDRESS"
            value={data.bankMailingAddress}
            onChange={(evt) =>
              handleChange("bankMailingAddress", evt.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
}
